import React, { useState } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';

const AddFunctionsCategoriesForm = props => {
	const initialFormState = { id: null, name: '' }
	const [ functionsCategories, setFunctionsCategories ] = useState(initialFormState)

	const handleInputChange = (name, value) => {
		setFunctionsCategories({ ...functionsCategories, [name]: value })
	}

	return (
		<form className="form-client"
			onSubmit={event => {
				event.preventDefault()
				if (!functionsCategories.name) return

				props.handleAddFunctionsCategories(functionsCategories)
				setFunctionsCategories(initialFormState)
			}}
		>
			<InputLabel
				name="name"
				type="text"
				value={functionsCategories.name}
				defaultStyle='secondary'
				label="Nom"
				onChange={(e) => { handleInputChange('name', e); }}/>
			
			
			<div className="div-button">
				<Button
					type="submit"
					defaultStyle="btn btn-primary"
					text="Enregistrer"
					textColor="primary"
				/>
			</div>
		</form>
	)
}
export default AddFunctionsCategoriesForm;
