/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import './booleancheckbox.style.scss';

const BooleanCheckbox = ({ checked, onChange, label, labelClass, containerClass, onlabel, offlabel, disabled }) => {

  return (
    <div className={`boolean_checkbox_with_label ${containerClass}`}>
      <div className="col label">
        <p className={labelClass} >{label} </p>
        </div>
        <div className="col">
          <label className="switch">
        <input
          checked={checked}
          onChange={onChange}
          type="checkbox" 
          disabled={disabled} />
        <span className="slider round">{checked ? onlabel: offlabel}</span>

      </label>
        </div>
    </div>


  );
}

BooleanCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
};

BooleanCheckbox.defaultProps = {
  checked: false,
  label: '',
  labelClass: '',
  containerClass: '',
}

export default BooleanCheckbox