/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './actionLink.style.scss';

function ActionLink({ to, className, activeClassName, fontWeight, color, content }) {

    return (
        <NavLink
            to={to}
            className={className}
            activeClassName={activeClassName}
            activeStyle={{
                fontWeight: { fontWeight },
                color: { color }
            }}

        >

            {content}
        </NavLink>

    );
}

ActionLink.propTypes = {
    to: PropTypes.object,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    // content: PropTypes.string,
};

ActionLink.defaultProps = {
    to: null,
    className: '',
    activeClassName: '',
    fontWeight: "bold",
    color: "#707070",
    // content: '',
}

export default ActionLink