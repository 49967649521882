import React from 'react';
import PropTypes from 'prop-types';

const Show = ({ color, fill, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <path
            d="M14 12c-1.095 0-2-.905-2-2c0-.354.103-.683.268-.973C12.178 9.02 12.092 9 12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3c1.641 0 3-1.358 3-3c0-.092-.02-.178-.027-.268c-.29.165-.619.268-.973.268z"
            fill={fill} />
        <path
            d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316l-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5c-.504 1.158-2.578 5-7.926 5z"
            fill={fill} />
    </svg>
);

Show.propTypes = {
    color: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

Show.defaultProps = {
    color: '#000',
    fill: '#0e384d',
    width: 32,
    height: 32,
};

export default Show;
