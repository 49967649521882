import React from 'react';
import PropTypes from 'prop-types';

const Circle = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 451.827 451.827">
    <g>
      <path
        color={color}
        fill={fill}
        d="M225.922,0C101.351,0,0.004,101.347,0.004,225.917s101.347,225.909,225.917,225.909
        c124.554,0,225.901-101.347,225.901-225.909C451.823,101.347,350.476,0,225.922,0z"  
      />
    </g>
  </svg>
);

Circle.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Circle.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Circle;