import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import LogsTable from './LogsTable';
import { getAllLogs } from 'services/API/Logs';
import Title from 'components/generics/Title';

const LogPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState(null);

    const getLogs = async () => {
        await getAllLogs()
            .then((g) => {
                setLogs(g.data.data);
                console.log(g.data.data);
                setIsLoading(true);
            })
            .catch(() => toast.error('Impossible de charger les logs'));
    }

    useEffect(() => {
        getLogs();
    }, []);


    return (
        <React.Fragment>
            {isLoading && 
            <div className="container">
                <Title
                className="div-header"
                text="Gestion des logs"
                 />

                <div className="div-list mt-3 table-responsive table-responsive-md">
                    {!_.isEmpty(logs) ? (
                        <LogsTable logs={logs} />
                    ) : (
                        <p className="message">Aucun Log disponible.</p>
                    )}
                </div>
            </div>
        }
        </React.Fragment>
    )
}
export default LogPage;