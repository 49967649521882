import React from 'react';
import { NavLink } from 'react-router-dom';
import Show from 'components/icons/Show';
import Edit from 'components/icons/Edit';
import { formatDate } from 'utils';
import Delete from 'components/icons/Delete';

const Quotestablerow = ({ data, handleRemoveQuote }) => {

    function formatStatus(status){
        switch (status) {
            case "En cours":
                return (<span className="badge rounded-pill bg-warning">{ status }</span>);
                // break;
            case "Validé":
                return (<span className="badge rounded-pill bg-primary">{ status }</span>);
                // break;
            case "Envoyé":
                return (<span className="badge rounded-pill bg-info">{ status }</span>);
                // break;
            case "Devis accepté":
                return (<span className="badge rounded-pill bg-success">{ status }</span>);
                // break;
            case "Devis refusé":
                return (<span className="badge rounded-pill bg-error">{ status }</span>);
                // break;
            case "Annulé":
                return (<span className="badge rounded-pill bg-secondary">{ status }</span>);
                // break;
            default:
                return (<span className="badge rounded-pill bg-light"> </span>);
                // break;
        }
        
    }

    return (
        <>
            {
                data && data.actions !== "D" &&
                <tr>
                    
                    <td>{data.quoteNumber ? `${data.quoteNumber}` : '-'}</td>
                    <td>{data.status ? formatStatus(data.status) : ''}</td>
                    <td>{data.createdAt ? `${formatDate(data.createdAt.date)}` : ''}</td>
                    <td>{data.updatedAt ? `${formatDate(data.updatedAt.date)}` : ''}</td>
                    <td>{data.sendAt ? formatDate(data.sendAt) : ''}</td>
                    <td>{data.amountTTC ? data.amountTTC.toFixed(2) : ''}</td>
                    <td >
                        <div className="actionContainer">
                            { data.status === "En cours" ? 
                            <NavLink to={{pathname: `/devis/edit/${data.slug}`, state: {quote : data}}} activeClassName="active">
                            <Edit />
                            </NavLink>
                            :null
                            }
                            <NavLink to={{pathname: `/devis/show/${data.slug}`, state: {quote : data}}} activeClassName="active">
                            <Show />
                            </NavLink>
                            <div className="div-delete-link"  onClick={() => handleRemoveQuote(data.id)}> 
                                <Delete />
                            </div>
                         </div>
                    </td>
                </tr>
            }

        </>
    );
}

export default Quotestablerow;
