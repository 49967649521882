import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';
// import useAUTH from 'providers/Auth/useAuth';
import logo from 'assets/images/logo/logo_couleur.jpg';
import './login.style.scss';

import LoginForm from './form';

const Login = () => {
  const { user } = useAuth();

  return user ? (
    <Redirect to="/" />
  ) : (
    <div className='login'>
      <div className='logoContainer'>
        <img className='logo m-auto d-block' src={logo} alt='logo Solution 909' />
        <h2 className='text-center title'>Connexion</h2>
      </div>
      <div className='container'>
        <LoginForm />
      </div>
    </div>

    );
};

export default Login;
