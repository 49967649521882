export default function QuoteSummary({quoteProduct, quote}) {
    console.log(quoteProduct);
    return (
      <>
        <div className="order-summary">
            <div className="table-responsive table-responsive-md">
                <table className="table invoice-table">
                    <thead className="bg-active">
                        <tr>
                            <th>Désignation</th>
                            <th className="text-center">JH</th>
                            <th className="text-center">Montant Unit.</th>
                            <th className="text-center">Montant HT</th>
                            
                            <th className="text-right">Montant TTC</th>
                        </tr>
                    </thead>
                    <tbody>
                    {quoteProduct && quoteProduct.map(item => (
                        <tr>
                            <td>
                                <div className="item-desc-1">
                                    <span><strong>{item.title}</strong></span>
                                    <small>{item.content.split(".").map(place => <p> {place} </p>) }</small>
                                </div>
                            </td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="text-center">{item.priceUnit.toFixed(2)}</td>
                            <td className="text-center">{item.priceHT.toFixed(2)}</td>
                            
                            <td className="text-right">{item.priceTTC.toFixed(2)}</td>
                        </tr>
                    ))}
</tbody>
<tfoot>
     

                        <tr>
                            <td colSpan="2" ></td>
                            <td colSpan="2" className="text-end">TOTAL HT</td>
                            <td className="text-end">{quote.amountHT.toFixed(2)} €</td>
                        </tr>

                        {
                            quote.isDiscount ?
                                <>
                                    <tr className="bold">
                                    <td colSpan="2" ></td>
                            <td colSpan="2" className="text-end">Remise globale HT</td>
                                        <td >{quote.discount.toFixed(2)} €</td>
                                    </tr>

                                    <tr className="bold">
                                    <td colSpan="2" ></td>
                            <td colSpan="2"  className=" text-end">TOTAL TTC</td>
                                        <td >{quote.amountTTC.toFixed(2)} €</td>
                                    </tr>
                                </>
                                :
                                <tr className="bold">
                                    <td colSpan="2" ></td>
                            <td colSpan="2"  className="text-end">TOTAL TTC</td>
                                    <td >{quote.amountTTC.toFixed(2)} €</td>
                                </tr>
                        }
                        {/* <tr>
                            <td className="text-end">TOTAL TVA (20,00 %)</td>
                            <td >{totals.totalTva.toFixed(2)} €</td>
                        </tr> */}
                        <tr>
                        <td colSpan="2" className="" ></td>
                            <td colSpan="2" className="text-end">TOTAL TVA (20,00 %)</td>
                            <td >{(quote.amountHT *0.2).toFixed(2)} €</td>
                        </tr>
                        <tr className="bold">
                        <td colSpan="2" ></td>
                            <td colSpan="2"  className="text-end">TOTAL TTC</td>
                            <td className="total-ttc">{quote.amountTTC.toFixed(2)} €</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
      </>
    )
  }