import React, { useState, useEffect } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';


const EditProductsForm = props => {
    const [ products, setProducts ] = useState(props.currentProducts)
  
    useEffect(
      () => {
        setProducts(props.currentProducts)
      },
      [ props ]
    )
    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]
  
    const handleInputChange = (name, value) => {
        setProducts({ ...products, [name]: value })
    }
  
    return (
      <form className="form-client"
        onSubmit={event => {
          event.preventDefault()
          props.handleUpdateProducts(products.id, products)
        }}
      >

            <InputLabel
                name="title"
                type="text"
                value={products.title}
                defaultStyle='secondary'
                label="Référence"
                onChange={(e) => { handleInputChange('title', e); }} />
            <InputLabel
                name="content"
                type="text"
                value={products.content}
                defaultStyle='secondary'
                label="Designation"
                onChange={(e) => { handleInputChange('content', e); }} />
            <InputLabel
                name="priceUnit"
                type="number"
                value={products.priceUnit}
                defaultStyle='secondary'
                label="Prix unitaire"
                onChange={(e) => { handleInputChange('priceUnit', e); }} />

        <div className="div-button">
          <Button
            type="submit"
            defaultStyle="btn btn-primary"
            text="Enregistrer"
            textColor="primary"
          />
          <Button 
            type="button"
            text="Annuler"  
            onClick={() => props.setEditing(false)} 
            defaultStyle="btn btn-secondary"
          />
        </div>
      </form>
    )
  }

export default EditProductsForm;
