export default function QuoteHeader({ logo }) {
    return (
      <>
        <div className="invoice-top">
            <div className="row">
                <div className="col-sm-6">
                    <div className="logo">
                        <img className='logo' src={logo} alt='logo solution 909' />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="invoice">
                        <h1>Devis</h1>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }