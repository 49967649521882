import API from 'services/API';

export const login = (creds) => API.post('/auth/login', creds);

export const forgotPassword = (data) => API.post('/auth/forgotten_password', data);

export const resetPassword = (token, data) => API.post(`/auth/reset_password/${token}`, data);

// REGISTER 
export const register = (data) => API.post('/register', data);
export const verifUserEmail = (token) => API.post(`/verify/${token}`);

export const confirmCreds = (creds) => API.post('/confirm', creds);


// USER
export const updateUser = (user) => API.patch(`/user/${user.id}/edit`, user);

export const deleteFakeUser = (user) => API.post(`/user/del/${user.slug}`, user);

// Annuaire
export const getAllUsers = () => API.get('/users');

export const getUser = (id) => API.get(`/user/${id}/profil`, id);
export const editProfil = (user) => API.patch(`/user/profil/edit`, user);

// Equipe 
export const getMyTeam = (id) => API.get(`/user/${id}/myuser`, id);

// 


export default { login };