import React from 'react'
import "./error.404.page.scss";

const PageNotFound = () => {
    return (
        <>
        <div className="center">
            <div className="circle">
                <div className="circle_text">404</div>
            </div>
            <div>
                <h3>La page que vous recherchez est introuvable.</h3>
            </div>
        </div>
        </>
    )
}

export default PageNotFound