import API from 'services/API';


//CLIENTS
// export const getAllClients = () => API.get(`/clients`);
export const getClient = (id) => API.get(`/clients/${id}`);
export const addClient = (client) => API.post(`/clients/new`, client);
export const updateClient = (client) => API.patch(`/clients/${client.id}/edit`, client);
export const updateManager = (client) => API.patch(`/clients/${client.id}/manager`, client);
export const addClientComment = (client) => API.patch(`/clients/${client.id}/comment`, client);
export const deleteFakeClient = (client) => API.post(`/clients/del/${client.slug}`, client);

//TYPE ORGANISMS
export const getAllTypeOrganism = () => API.get(`/type-organisms`);
export const addTypeOrganism = (typeOrganism) => API.post(`/type-organisms/new`, typeOrganism);
export const updateTypeOrganism = (typeOrganism) => API.patch(`/type-organisms/${typeOrganism.id}/edit`, typeOrganism);

//FUNCTION CATEGORIES
export const getAllFunctionsCategories = () => API.get(`/functions-categories`);
export const addFunctionsCategories = (functionsCategories) => API.post(`/functions-categories/new`, functionsCategories);
export const updateFunctionsCategories = (functionsCategories) => API.patch(`/functions-categories/${functionsCategories.id}/edit`, functionsCategories);

//FILES CATEGORIES
export const getAllFilesCategories = () => API.get(`/filescat`);
export const addFilesCat = (filesCat) => API.post(`/filescat/new`, filesCat);
export const updateFilesCat = (filesCat) => API.patch(`/filescat/${filesCat.id}/edit`, filesCat);

//CONTACTS CLIENT
// export const getAllContacts = (id) => API.get(`/clients/${id}/contacts`);
export const addContact = (contact) => API.post(`/clients/${contact.clientId}/contacts/new`, contact);
export const updateContact = (contact) => API.patch(`/clients/${contact.clientId}/contacts/edit`, contact);


//DATA CONTROLLER
export const addDataController = (dc) => API.post(`/clients/${dc.clientId}/dc/new`, dc);
export const updateDataController = (dc) => API.patch(`/clients/${dc.clientId}/dc/edit`, dc);

export const getAllDocumentss = (id) => API.get(`/clients/${id}/docs`);


//UPLAOD DOCUMENTS FILES
export const uploadFiles = (clientId, formData, config) => API.post(`/clients/${clientId}/docs/add`, formData, config);
export const getFiles = (id) => API.get(`/clients/${id}/docs`);
export const showClientDocument = (id) => API.get(`/docs/show/${id}`);

// export default {
//   getClient,
//   addClient,
//   updateClient,
// };
