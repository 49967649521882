/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import './checkbox.style.scss';

const Checkbox = ({value, onChange, checked, label, labelClass, containerClass }) => (
  <div onClick={() => onChange(value) } className={`checkbox_with_label ${containerClass}`}>
    <div className='checkbox_container'>
      <div className={`${checked ? 'checkbox_checked' : ''}`} />
    </div>
    <p className={labelClass}>{label}</p>
  </div>
);

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  label: '',
  labelClass: '',
  containerClass: '',
}

export default Checkbox