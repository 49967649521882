import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z M15.293 14.707a.999.999 0 0 0 1.414 0l5-5l-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5l1.414 1.414L13 12.414l2.293 2.293z"  
      />
    </g>
  </svg>
);

Edit.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Edit.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Edit;