import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import {verifUserEmail } from "services/API/User";

const VerifyEmailPage = () => {
    const location = useLocation();

    const history = useHistory();
    const EmailStatus = {
        Verifying: 'Verifying',
        Failed: 'Failed'
    }

    const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

    useEffect(() => {
        
        const { token } = queryString.parse(location.search);

        // remove token from url to prevent http referer leakage
        history.replace(location.pathname);
        // console.log(token);
        verifUserEmail(token)
        // accountService.verifyEmail(token)
            .then((response) => {
                // console.log(response);
                if (response.status === 200){
                    toast.success(response.data);
                    history.push({pathname: '/confirm', state: { token: token }});
                }
                else if (response.status === 201){
                    toast.success(response.data);
                    history.push('/');
                }

            })
            .catch(() => {
                setEmailStatus(EmailStatus.Failed);
            });
    }, []);

    function getBody() {
        switch (emailStatus) {
            case EmailStatus.Verifying:
                return <div>Vérification en cours...</div>;
            case EmailStatus.Failed:
                return <div>La vérification a échoué, vous pouvez également vérifier votre compte en utilisant la page <Link to="forgotPassword">mot de passe oublié</Link>.</div>;
        }
    }

    return (
        <div>
            <h3 className="card-header">Verify Email</h3>
            <div className="card-body">{getBody()}</div>
        </div>
    );
}

export default VerifyEmailPage;
