import React, { useState } from 'react';

import Select from 'components/generics/Select';
import Input from 'components/generics/Input';

import "./quotetablerow.style.scss";
import { calculateHtAmount, calculateTTCAmount } from 'utils';

export default function QuoteTableRow({ row, handleDataChange, deleteRow, products, tva }) {
    const index = row.id;
    console.log(products);
    const [data, setData] = useState(row);

    function handleChange(key, value) {

        console.log(value);
        let newData = {};
        if (key == 'title') {
            let product = null;
            product = products.find((type) => {
                if (value == type.id) {
                    return type;
                }
            });

            newData = {
                id: index,
                title: product.title,
                content: product.content,
                quantity: 0,
                priceUnit: product.priceUnit,
                priceHT: 0,
                priceTTC: 0
            };

        }
        else if (key == 'content') {
            setData({ ...data, [key]: value });
        }
        else if (key == 'quantity') {
            let montant_ht = calculateHtAmount(value, data.priceUnit);
            let montant = calculateTTCAmount(montant_ht, tva);
            newData = {
                id: index,
                title: data.title,
                content: data.content,
                quantity: value,
                priceUnit: data.priceUnit,
                priceHT: montant_ht,
                priceTTC: montant
            };
        }
        else if (key == 'priceUnit') {
            let montant_ht = calculateHtAmount(data.quantity, value);
            let montant = calculateTTCAmount(montant_ht, tva);
            newData = {
                id: index,
                title: data.title,
                content: data.content,
                quantity: data.quantity,
                priceUnit: value,
                priceHT: montant_ht,
                priceTTC: montant
            };
        }
        else if (key == 'priceHT') {
            let montant_ht = calculateHtAmount(data.quantity, value);
            let montant = calculateTTCAmount(montant_ht, tva);
            newData = {
                id: index,
                title: data.title,
                content: data.content,
                quantity: data.quantity,
                priceUnit: data.priceUnit,
                priceHT: value,
                priceTTC: montant
            };
        }
        setData(newData);
        handleDataChange(newData);
    }

    const removeRow = () => {
        deleteRow(index)
    }

    return (
        <tr className={`prestation ${index}`}>
            <td>
                <Select
                    className='secondary'
                    name={`quote_products[${index}][title]`}
                    onChange={(e) => {
                        handleChange('title', e);
                    }}
                    options={products}
                    value={data.title}
                    itemLabel='title'
                    itemValue='id'
                />
                <Input type="hidden" name={`quote_products[${index}][title]`} value={data.title}/>
            </td>
            <td>
                <textarea
                    type="text"
                    rows="5"
                    name={`quote_products[${index}][content]`}
                    className="presta_content"
                    value={data.content}
                    onChange={(e) => {
                        handleChange('content', e.target.value);
                    }} />
            </td>

            <td>
                <Input type="number"
                    name={`quote_products[${index}][quantity]`}
                    defaultStyle="presta_quantite"
                    value={data.quantity}
                    onChange={(e) => {
                        handleChange('quantity', e);
                    }} />
            </td>
            <td>
                <Input type="number"
                    name={`quote_products[${index}][priceUnit]`}
                    defaultStyle="presta_prix_unit"
                    value={data.priceUnit.toFixed(2)}
                    onChange={(e) => {
                        handleChange('priceUnit', e);
                    }} />
            </td>
            <td>
                <Input type="number"
                    name={`quote_products[${index}][priceHT]`}
                    defaultStyle="presta_prix"
                    value={data.priceHT.toFixed(2)}
                    onChange={(e) => {
                        handleChange('priceHT', e);
                    }}
                    disabled={true} />
                <Input type="hidden" name={`quote_products[${index}][priceHT]`} value={data.priceHT.toFixed(2)}/>
            </td>
            <td>
                <Input type="number"
                    name={`quote_products[${index}][priceTTC]`}
                    defaultStyle="presta_prix_ttc"
                    value={data.priceTTC.toFixed(2)}
                    disabled={true} />
                <Input type="hidden" name={`quote_products[${index}][priceTTC]`} value={data.priceTTC.toFixed(2)}/>
            </td>
            <td>
                <button type="button" className="btn btn-remove" onClick={removeRow}>&times;</button>
            </td>
        </tr>
    )
}


