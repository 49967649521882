import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21A1 1 0 0 0 4 21zM18 4.41L19.59 6L18 7.59L16.42 6zM5.91 16.51L15 7.41L16.59 9l-9.1 9.1l-2.11.52z"
      />
    </g>
  </svg>
);

Edit.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Edit.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Edit;