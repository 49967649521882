import React, { useState } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';

const AddFilesCatForm = props => {
	const initialFormState = { id: null, type: '', description: '' }
	const [ filesCat, setFilesCat ] = useState(initialFormState)

	const handleInputChange = (name, value) => {
		setFilesCat({ ...filesCat, [name]: value })
	}

	return (
		<form className="form-client"
			onSubmit={event => {
				event.preventDefault()
				if (!filesCat.type || !filesCat.description) return

				props.handleAddFilesCat(filesCat)
				setFilesCat(initialFormState)
			}}
		>
			<InputLabel
				name="name"
				type="text"
				value={filesCat.type}
				defaultStyle='secondary'
				label="Type de fichier"
				onChange={(e) => { handleInputChange('type', e); }}/>
			<InputLabel
				name="description"
				type="text"
				value={filesCat.description}
				defaultStyle='secondary'
				label="Description"
				onChange={(e) => { handleInputChange('description', e); }}/>
			
			
			<div className="div-button">
				<Button
					type="submit"
					defaultStyle="btn btn-primary"
					text="Enregistrer"
					textColor="primary"
				/>
			</div>
		</form>
	)
}
export default AddFilesCatForm;

