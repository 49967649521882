import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import SelectLabel from 'components/generics/SelectLabel';
import ButtonsForm from 'components/generics/ButtonsForm';


import useAuth from 'providers/Auth/useAuth';
import { convertIdOrganismType } from 'utils';

import { getClient } from 'services/API/Clients';
import { getAllProducts, getAllQuoteStatus } from 'services/API/Quotes';
import QuoteProducts from './QuoteProducts';
import InputLabel from 'components/generics/InputLabel';
import Title from 'components/generics/Title';

import "./quoteform.style.scss";
import IconButton from 'components/generics/IconButton';
// import FilePdf from 'components/icons/FilePdf';
import { renderToString } from 'react-dom/server';
// import QuoteDocument from './QuoteDocument';
import PdfDocument from 'components/generics/PdfDocument';
import QuoteDocument from '../Show/QuoteDocument';
import Show from 'components/icons/Show';

const TVA = 20;

export default function QuoteForm(props) {
    const { user } = useAuth();
    let clients = user.clients ? user.clients : null;
    let isEditMode = props.quote ? true : false;

    const [client, setClient] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [contact, setContact] = useState({ id: 1, ccName: "Contact...." });
    const [quote, setQuote] = useState(props.quote ? props.quote : null);
    const [quoteProducts, setQuoteProducts] = useState(null);
    const [quoteStatus, setQuoteStatus] = useState(null);
    const [products, setProducts] = useState(null);
    const [isSelectedClient, setIsSelectedClient] = useState(false);

    useEffect(() => {
        const getProducts = async () => {
            await getAllProducts()
                .then((g) => {
                    setProducts(g.data.data);
                })
                .catch(() => toast.error('Impossible de charger les produits'));
        }
        getProducts();

        if (isEditMode) {
            setClient(quote.client);
            getQuoteClient(quote.client);
            setIsSelectedClient(true);
            setContact(quote.contact);
            setQuoteProducts(quote.quoteProducts);
        }
        else {
            if (clients) {
                getQuoteClient(clients[0]);
            }
            setClient(clients[0]);
        }
    }, []);

    useEffect(() => {
        const getQuoteStatus = async () => {
            await getAllQuoteStatus()
                .then((g) => {
                    // console.log(g.data.data);
                    let nextStatus = [];
                    if (quote) {

                        switch (quote.status) {
                            case 'En cours':
                                nextStatus = ['En cours', 'Validé', 'Annulé'];
                                break;
                            case 'Validé':
                                nextStatus = ['Validé', 'Envoyé', 'Annulé'];
                                break;
                            case 'Envoyé':
                                nextStatus = ['Envoyé', 'Devis accepté', 'Devis refusé', 'Annulé'];
                                break;
                            default:
                                nextStatus = ['Annulé'];
                                break;
                        }
                    }
                    else {
                        nextStatus = ['En cours', 'Validé', 'Annulé'];
                    }
                    var copie = [];
                    nextStatus.forEach(element => {
                        copie.push(getListStatus(element, g.data.data));

                    });

                    setQuoteStatus(copie);
                })
                .catch(() => toast.error('Impossible de charger les status des devis'));
        }
        getQuoteStatus();

    }, []);

    function getListStatus(name, object) {
        let typeOrg = null;
        typeOrg = object.find((typ) => {
            if (name === typ.name) {
                return typ;
            }
        });

        return typeOrg;
    }


    function validate(formData) {
        let isErr = false;

        return isErr;
    }
    function getQuoteClient(clt) {
        getClient(clt.id)
            .then((resp) => {
                setClient(resp.data.data);
                if (resp.data.data.id === clt.id) {
                    setClient(resp.data.data);
                    setContacts(resp.data.data.clientsContacts)
                }
            })
            .catch((err) => toast.error(err.data));
    }

    const handleData = (tabTot, talbeRows) => {
        setQuoteProducts(talbeRows);
        setQuote(tabTot);
    }

    const handleOnSubmit = (event) => {
        if (event) event.preventDefault();

        var formElement = document.querySelector("form");
        const formdata = new FormData(formElement);
        props.handleOnSubmit(formdata);

    };

    function getQuoteDocument(quote) {
        return <QuoteDocument quote={quote} />;
    }
    const handleDownload = (quote) => {
        let htmlData = getQuoteDocument(quote);
        // console.log(htmlData);
        PdfDocument(renderToString(htmlData), "devis");
    };
    return (
        // { quote ? 
        <div className="container-fluid  " >
            <div className='div-header'>
                <Title
                    className="div-header"
                    text={isEditMode ? "Edition du devis " + quote.quoteNumber : "Nouveau devis"}
                />
                <IconButton text="visualisation"
                    onClick={() => handleDownload(quote)}
                    icon={<Show width={35.0} height={35.0} />} />
            </div>


            <div className="div-quote">
                <form className="form-client" onSubmit={handleOnSubmit}>
                    <div className="row mb-4">
                        <div className="col-12 col-lg-6">
                            {isEditMode ?
                                <InputLabel
                                    label="Nom du client"
                                    name='client'
                                    // defaultStyle='secondary'
                                    value={quote.client.clName}
                                    disabled={true}
                                    onChange={{}}
                                />

                                :
                                <SelectLabel
                                    label="Nom du client"
                                    className='secondary'
                                    name='client'
                                    onChange={(e) => {
                                        // console.log(convertIdOrganismType(+e, clients));
                                        setClient(convertIdOrganismType(+e, clients));
                                        getQuoteClient(convertIdOrganismType(+e, clients))
                                        setIsSelectedClient(true);
                                    }}
                                    options={clients}
                                    value={client ? client.id : clients[0].id}
                                    itemLabel='clName'
                                    itemValue='id'
                                />
                            }
                        </div>
                        <div className="col-12 col-lg-6">
                            {isEditMode ?
                                <InputLabel
                                    label="Nom du contact"
                                    name='contact'
                                    // defaultStyle='secondary'
                                    value={quote.contact.ccName}
                                    disabled={true}
                                    onChange={{}}
                                />

                                :
                                (
                                    contacts &&
                                    <SelectLabel
                                        label="Nom du contact"
                                        className='form'
                                        name='contact'
                                        onChange={(e) => {
                                            setContact(convertIdOrganismType(+e, contacts));
                                        }}
                                        options={contacts}
                                        value={contact.id}
                                        itemLabel='ccName'
                                        itemValue='id'
                                        disabled={isSelectedClient}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {quoteStatus ?
                        <div className="row mt-4 ">
                            <div className="col-12 mb-4">
                                <SelectLabel
                                    label="Statut du devis"
                                    className='form'
                                    name='status'
                                    onChange={(e) => {
                                        console.log(quoteStatus);
                                        setQuoteStatus(convertIdOrganismType(+e, quoteStatus));
                                    }}
                                    options={quoteStatus}
                                    value={quote && quote.status ? quote.status.id : ""}
                                    itemLabel='name'
                                    itemValue='id'
                                // disabled={isSelectedClient}
                                />
                            </div>
                        </div> : null
                    }
                    <div className="row mt-4 ">
                        <div className="col-12 mb-4">
                            Ajouter les produits pour générer le devis
                        </div>
                        <div className="col">
                            {products &&
                                <QuoteProducts isEditMode={isEditMode} quote={quote} handleData={handleData} products={products} tva={TVA} />
                            }

                        </div>
                    </div>

                    <ButtonsForm route={`/devis`} isSaveButton={isEditMode ? true : false} isBackButton={true} />
                </form>
            </div>
        </div>
        // :
        // null

        // }
    )
}
