import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';

import ClientForm from './ClientForm';

import { updateClient } from '../../../../services/API/Clients';
import { getAllTypeOrganism } from 'services/API/Clients';


const EditClient = () => {
    const history = useHistory();
    const location = useLocation();
    let clientToEdit = location.state.client ? location.state.client : null;
    const [organismTypes, setOrganismTypes] = useState(null);

    useEffect(() => {
        const getTypeOrganism = async () => {
              await getAllTypeOrganism()
                .then((g) => {
                    setOrganismTypes(g.data.data);
                })
                .catch(() => toast.error('Impossible de charger les types d\'organisme'));
          }
        getTypeOrganism();
      }, [ ]);


    const handleOnSubmit = (client) => {
        console.log("Edit client handleOnSubmit");
        console.log(client);

        updateClient(client)
            .then(() => {
                toast.success(`Client ${client.clName} mis à jour`);
                history.push('/clients/all');
            })
            .catch((err) => toast.error(err.response.data['message']));
    };

    return (
        <div>
            {clientToEdit &&
                <ClientForm organismTypes={organismTypes} client={clientToEdit} handleOnSubmit={handleOnSubmit} />
            }
        </div>
    );
};

export default EditClient;
