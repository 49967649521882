import React from 'react';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Auth from 'providers/Auth';
import Router from 'router/index';

import 'assets/styles/index.scss';

const App = () => (
<>
    <Auth>
        <Router />
    </Auth>
    <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
</>
);

export default App;

