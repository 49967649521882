export default function QuoteFooter() {
    return (
      <>
        <div className="invoice-informeshon">
            <div className="row mb-2">
                <div className="col-sm-12">
                    <h4 className="inv-title-1">NOTES</h4>
                    <p className="text-muted">Les coûts indiqués ne comprennent pas les coûts et prestations éventuelles coté fournisseurs.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-10">
                    <div className="payment-info">
                        <h3 className="inv-title-1">Informations de paiement</h3>
                        <ul className="bank-transfer-list-1 list-unstyled">
                            <li><strong>Conditions de réglement: </strong>30% à la commande,le solde 30j à réception de facture </li>
                            <li><strong>Mode de réglement: </strong>Virement</li>
                            {/* <li>Ce devis est valable 30 jours à compter de sa date de réalisation.</li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-sm-10">
                    <div className="terms-and-condistions text-start">
                        <p className="mb-0">Ce devis est valable 30 jours à compter de sa date de réalisation.</p>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12 text-center">
                    <p className="text-muted">Solutions 909 - SASU à capital variable – SIRET 84393632900013 - R.C.S. Aix En Provence 843 936 329 – Code APE 5829C
Siège Social 6 rue du Capricorne 13320 BOUC BEL AIR – Tél : +33 7 67 98 59 88</p>
                </div>
            </div>
        </div>

      </>
    )
  }