import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';

import logo from 'assets/images/logo/logo_couleur.jpg';
import './ForgotPassword.style.scss';

import ForgotForm from 'components/pages/ForgotPassword/form';

const ForgotPassword = () => {
  const { user } = useAuth();

  return user ? (
    <Redirect to="/" />
  ) : (
      <div className='forgotpassword'>
        {/* <div className='form'> */}
          <div className='logoContainer'>
            <img className='logo m-auto d-block' src={logo} alt='logo solution 909' />
            <h2 className='text-center title'>Récuperation mot de passe</h2>
          </div>
          <div className='container'>
            
            <ForgotForm />
          </div>
        {/* </div>
        <div className='img' /> */}
      </div>
    );
};

export default ForgotPassword;
