import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";
// import _ from 'lodash';

import { toast } from 'react-toastify';
// import Table from 'components/generics/Table/Table';
// import DocTableRow from './DocTableRow';

import { getAllFilesCategories } from 'services/API/Clients';
// import Addfile from './AddFile';

import UploadFiles from './UploadFiles';
// import { getFiles } from 'services/API/Clients';
import Back from 'components/icons/Back';
// const HEADER_TABLE = ['Nom', 'Catégory', 'Taille', 'Ajouté le', ''];

export default function ClientsDocsPage() {
    const location = useLocation();
    let client = location.state.client;
    const [filesCategories, setFilesCategories] = useState(null);
    // const [docs, setDocs] = useState(location.state.client.clientsDocs);

    useEffect(() => {
        const getFilesCategories = async () => {
            await getAllFilesCategories()
                .then((g) => {
                    setFilesCategories(g.data.data);
                })
                .catch((err) => {
                    toast.error("impossible : " + err.response.data['message'])
                });
        }
        getFilesCategories();

        // if (client){
        //     getFiles(client.id).then((resp) => {
        //         if (resp.status === 200) {
        //             setDocs(resp.data.data);
        //           } else if (resp.status === 204) {
        //             setDocs(null);
        //           } else {
        //             // toast.error( err.response.data['message'])
        //           }

        //         // console.log(resp.data.data);
        //         // if (resp.data.data.lenght > 0 ){
        //         //     setDocs(resp.data.data);
        //         // }
                
        //         // console.log(resp.data.data);
        //     // }).catch((err) => {
        //     //     toast.error( err.response.data['message'])
        //     });
        // }
        // else{
        //     toast.error("Pas de client trouvé ");
        // }


    }, [client]);

    // const handleRemoveDoc = (id) => {
    //     //TODO
    // };

    return (
        <React.Fragment>
            {filesCategories &&
                <div>

                    <div className="div-header">
                        <h1 className="text-center">Liste des documents</h1>

                        <NavLink to={{pathname: `/client/show/${client.slug}`, state: {client : client}}} activeClassName="active"><Back /></NavLink>

                    </div>


                    <div className="container">
                        <div className="my-2">
                            <h3>Ajouter des documents</h3>
                            <p>Taille maximale: 10 Mo par import et uniquement des fichiers PDF</p>
                        </div>

                        <UploadFiles clientId={client.id} filesCategories={filesCategories} />
                    </div>

                    {/* <div className="div-list table-responsive-md">
                        {filesCategories && !_.isEmpty(docs) ? (
                            <Table header={HEADER_TABLE} data={docs} RowElement={DocTableRow} handleRemoveDoc={handleRemoveDoc} />
                        ) : (filesCategories ?
                            <p className="message">Aucun document disponible. Veuillez ajouter des documents.</p> : null
                        )}
                    </div> */}

                    {/* <div className="div-button">
                    </div> */}
                </div>
            }
        </React.Fragment>
    )
}
