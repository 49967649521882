import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel';
// import Label from 'components/generics/Label';
// import Select from 'components/generics/Select';
import TextareaLabel from 'components/generics/TextareaLabel';
import ButtonsForm from 'components/generics/ButtonsForm';

import { getSiret } from 'services/SIRET/siret';
import { validStr, convertIdFunctionCategories } from 'utils';
import SelectLabel from 'components/generics/SelectLabel';
import Title from 'components/generics/Title';
import Input from 'components/generics/Input';

import "./datacontrollerform.style.scss";

const DataControllerForm = (props) => {
    const [formData, setFormData] = useState(props.formData);
    let isEditMode = props.isEditMode ? props.isEditMode : false;
    let functionsCat = props.functionsCat ? props.functionsCat : null;

    useEffect(() => {
        PersonnStatus(formData.cdcLegalEntityFlag);
    }, [formData]);

    function PersonnStatus(cdcLegalEntityFlag) {
        // Personne moral
        if (cdcLegalEntityFlag == false) {
            document.querySelector('#cdcLegalEntityFlag_2').setAttribute('checked', 'checked');
            UpdateFields(0);
        }
        else {
            document.querySelector('#cdcLegalEntityFlag_1').setAttribute('checked', 'checked');
            UpdateFields(1);
        }
    }

    function UpdateFields(value) {
        // Personne morale
        if (value == 0) {
            document.querySelector('#cdcRegistrationNumber').removeAttribute('disabled');
            document.querySelector('#cdcRegistrationNumber').style.background = "#fef4e2";
            document.querySelector('select[name="functionsCategories"]').setAttribute('disabled', 'disabled');
            document.querySelector('select[name="functionsCategories"]').style.background = "#eeeded";
            document.querySelector('input[name="cdcFirstname"]').setAttribute('disabled', 'disabled');
            document.querySelector('input[name="cdcFirstname"]').style.background = "#eeeded";
        } else {
            document.querySelector('#cdcRegistrationNumber').setAttribute('disabled', 'disabled');
            document.querySelector('#cdcRegistrationNumber').style.background = "#eeeded";
            document.querySelector('select[name="functionsCategories"]').removeAttribute('disabled');
            document.querySelector('select[name="functionsCategories"]').style.background = "#fef4e2";
            document.querySelector('input[name="cdcFirstname"]').removeAttribute('disabled');
            document.querySelector('input[name="cdcFirstname"]').style.background = "#fef4e2";
        }
    }

    function validate(formData) {
        let isErr = false;
        if (formData['cdcLegalEntityFlag'] === "") {
            isErr = true;
            toast.error('Personne physique ou légal non sélectionné');
        }

        if (!validStr.test(formData['cdcName'])) {
            isErr = true;
            toast.error('Nom format erreur');
        }

        if (formData['cdcLegalEntityFlag'] === true) {
            if (!isErr && !validStr.test(formData['cdcFirstname'])) {
                isErr = true;
                toast.error('prénom format erreur');
            }
        }
        return isErr;

    }

    const handleOnSubmit = (event) => {
        if (event) event.preventDefault();

        if (!validate(formData)) {
            props.handleOnSubmit(formData);
        }
    };

    function handleSiret(value) {

        getSiret(value)
        .then((data) => {
                let objet = data.data.etablissement;
                //donnee est la variable reçu du serveur avec les résultats
                var nom = "";

                if (typeof objet.unite_legale === 'object' && objet.unite_legale != null) {
                    // console.log("dans objet unite_legale");
                    nom = objet.unite_legale.denomination;
                } else {
                    // console.log("else not in objet unite_legale");
                    nom = objet.denomination;
                }

                document.querySelector('#cdcName').setAttribute("value", nom);
                handleInputChange('cdcName', nom);
            
        })
        .catch(() => toast.error('Pas de SIRET trouvé'));
    }


    function handleInputChange(name, value) {
        if (name === 'cdcRegistrationNumber') {
            if (value.length === 14) {
                handleSiret(value);
                formData['cdcRegistrationNumber'] = value;
            }
        }
        else if (name === 'cdcLegalEntityFlag') {
            // Personne morale
            if (value === "0") {
                setFormData({
                    ...formData,
                    ['cdcLegalEntityFlag']: value,
                    ['cdcFirstname']: ''
                });
            } else {
                setFormData({
                    ...formData,
                    ['cdcLegalEntityFlag']: value,
                    ['cdcRegistrationNumber']: ''
                });
            }
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    }


    return (

        <div className="container main-form" >

            <Title className="div-header"
                text={isEditMode ? "Editer un représentant légal " : "Ajouter un représentant légal "}
                />
            <div className="div-form">

                {formData &&
                    <form className="form-client" onSubmit={handleOnSubmit}> 
                        <div className="row">
                            <div className="col d-flex">
                                <label className="radio-inline">
                                    <input
                                        type="radio"
                                        name="cdcLegalEntityFlag"
                                        id="cdcLegalEntityFlag_1"
                                        value="1" 
                                        onChange={(e) => { handleInputChange('cdcLegalEntityFlag', e.target.value); }} />Personne physique
                                </label>
                                <label className="radio-inline">
                                    <input type="radio" 
                                    name="cdcLegalEntityFlag" 
                                    id="cdcLegalEntityFlag_2" 
                                    value="0" 
                                    onChange={(e) => { handleInputChange('cdcLegalEntityFlag', e.target.value); }} />Personne morale
                                </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="cdcRegistrationNumber"
                                    value={formData.cdcRegistrationNumber}
                                    defaultStyle='secondary'
                                    label="SIRET"
                                    disabled={false}
                                    type="text"
                                    onChange={(e) => { handleInputChange('cdcRegistrationNumber', e); }} />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="cdcName"
                                    value={formData.cdcName}
                                    defaultStyle='secondary'
                                    label="Nom"
                                    onChange={(e) => { handleInputChange('cdcName', e); }} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="cdcFirstname"
                                    value={formData.cdcFirstname}
                                    defaultStyle='secondary'
                                    label="Prénom"
                                    onChange={(e) => { handleInputChange('cdcFirstname', e); }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col select-fct">
                                <SelectLabel
                                    defaultStyle='secondary width-middle'
                                    label="Fonction"
                                    name='functionsCategories'
                                    onChange={(e) => {
                                        handleInputChange('functionsCategories', convertIdFunctionCategories(+e, functionsCat));
                                    }}
                                    options={functionsCat}
                                    value={formData.functionsCategories ? formData.functionsCategories.id : ""}
                                    itemLabel='name'
                                    itemValue='id'
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col ">
                                <TextareaLabel
                                    id="text-area"
                                    name="cdcInformations"
                                    value={formData.cdcInformations}
                                    defaultStyle='secondary'
                                    label="Informations"
                                    onChange={(e) => {
                                        handleInputChange('cdcInformations', e.target.value);
                                    }} />
                            </div>
                        </div>

                        <ButtonsForm route={`/clients/all`} isSaveButton={true} isBackButton={true} />
                    </form>
                }</div>
        </div>
    )
};

export default DataControllerForm;
