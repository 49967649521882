import React from 'react';

import "./card.style.scss";
const Card = ({ route, title, icon, subtitle }) => {
    return (

        <a className="courses-container" href={route}>
            <div className="course">
                <div className="course-preview">
                    <h2>{title}</h2>
                    <small>{subtitle}</small>

                    {/* <div className="row progress-alignment">
                       
                    </div> */}

                </div>

                <div className="course-info">
                    <div className="progress-container">
                        <p className="icon">{icon}</p>
                    </div>

                </div>
            </div>
            <div className="progress progress-card">
                <div className="progress-bar"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="10">
                </div>
            </div>

        </a>
    );
}

export default Card;
