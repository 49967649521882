import React from 'react';
import PropTypes from 'prop-types';

const DocumentPlus = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18l5 5h-5V4zM8 14h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z"
      />
    </g>
  </svg>
);

DocumentPlus.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DocumentPlus.defaultProps = {
  color: '#0e384d',
  fill: '#0e384d',
  width: 32,
  height: 32,
};

export default DocumentPlus;
