import React from 'react';
import PropTypes from 'prop-types';
import { v1 } from 'uuid';

import './select.style.scss';

const Select = ({ 
  name, 
  onChange, 
  options, 
  value, 
  itemLabel, 
  itemValue, 
  className, 
  disabled
}) => (
    <select className={`form-control ${className}`} 
    name={name} 
    disabled={disabled}
    onChange={e => onChange(e.target.value)} 
    value={value}
    
    >
      {options.map(option => (
        <option value={option[itemValue]} key={v1()}>{option[itemLabel]}</option>
        ))}
    </select>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemLabel: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

Select.defaultProps = {
  options: [],
  value: '',
  className: '',
  disabled: false
}

export default Select;