import React from 'react';
import PropTypes from 'prop-types';

const Bolt = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M11 21h-1l1-7H7.5c-.88 0-.33-.75-.31-.78C8.48 10.94 10.42 7.54 13.01 3h1l-1 7h3.51c.4 0 .62.19.4.66C12.97 17.55 11 21 11 21z"
      />
    </g>
  </svg>
);

Bolt.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Bolt.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Bolt;