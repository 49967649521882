import React from 'react';
// import { NavLink } from 'react-router-dom';

import ClientTableRow from './ClientTableRow';
import _ from 'lodash';
import Table from 'components/generics/Table/Table';
// import UserPlus from 'components/icons/UserPlus';
import Title from 'components/generics/Title';
import FilePdf from 'components/icons/FilePdf';

const HEADER_TABLE = ['Statut', 'Code', 'Client', 'Groupe', 'Options', 'Contacts', 'Doc', ''];

const ClientsHome = ({ clients }) => {

  const handleRemoveClient = (id) => {

    console.log("handleRemoveClient");
  };

  return (
    <>
      <Title
        className=""
        text="Liste des clients" 
        isAddButton={true}
        routeAdd= "/client/add"
        />
      <div className="div-list table-responsive table-responsive-md table-responsive-sm">
        {!_.isEmpty(clients) ? (
          <Table header={HEADER_TABLE} data={clients} RowElement={ClientTableRow} handleRemoveClient={handleRemoveClient} />
        ) : (
          <p className="message">Aucun client disponible. Veuillez ajouter des clients.</p>
        )}
      </div>
    </>
  );
}

export default ClientsHome;
