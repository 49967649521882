/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import useAuth from 'providers/Auth/useAuth';

import Topbar from 'components/pages/navigation/Topbar/Topbar';
import Sidebar from 'components/pages/navigation/Sidebar/Sidebar';

const ProtectedRoute = ({ children }) => {
  const { user, logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      try {
        jwtDecode(localStorage.getItem('jwt_token'));
      } catch (error) {
        // TODO : ADD TOAST
        logout();
        history.push('/login');
      }
    }
  }, [user]);

  return (user && (

    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div className="home-content">
        < Topbar />
        {children}
      </div>
    </div>


  )
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
