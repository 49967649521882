import React from 'react';
import PropTypes from 'prop-types';

// import Input from 'components/generics/Input';
import './textareaLabel.style.scss';

const TextareaLabel = ({
  defaultStyle,
  disabled,
  name,
  required,
  rows,
  value,
  onChange,
  color,
  placeholder,
  label,
}) => (
  <label className={defaultStyle} htmlFor={name}>
    {label}
    <textarea
      onChange={onChange}
      value={value}
      id={name}
      className={defaultStyle}
      disabled={disabled}
      name={name}
      required={required}
      rows={rows}
    //   style={color}
    style={{ borderColor: color }}
      placeholder={placeholder}
      aria-label={name}
    /> 

  </label>
);

TextareaLabel.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string ,
  label: PropTypes.string.isRequired,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  color: PropTypes.string,
  placeholder: PropTypes.string
};

TextareaLabel.defaultProps = {
  defaultStyle: '',
  disabled: false,
  name: '',
  required: false,
  rows: 3,
  color: '',
  placeholder: '',
  value:''
};

export default TextareaLabel;
