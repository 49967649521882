import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import FunctionsCategoriesTable from './FunctionsCategoriesTable';
import AddFunctionsCategoriesForm from './AddFunctionsCategoriesForm';
import EditFunctionsCategoriesForm from './EditFunctionsCategoriesForm';
import { getAllFunctionsCategories, addFunctionsCategories, updateFunctionsCategories } from 'services/API/Clients';

import 'assets/styles/index.scss';

import './functionscategories.style.scss';

const FunctionsCategoriesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [functionsCategories, setFunctionsCategories] = useState(null);
  const initialFormState = { id: null, name: '' }

  // Setting state
  const [currentFunctionsCategories, setCurrentFunctionsCategories] = useState(initialFormState)
  const [editing, setEditing] = useState(false)

  const getFunctionsCategories = async () => {
    await getAllFunctionsCategories()
      .then((g) => {
        setFunctionsCategories(g.data.data);
        setIsLoading(true);
      })
      .catch(() => toast.error('Impossible de charger les status des devis'));
  }

  useEffect(() => {
    getFunctionsCategories();
  }, []);

  // CRUD operations
  const handleAddFunctionsCategories = obj => {
    addFunctionsCategories(obj)
      .then(() => {
        getFunctionsCategories();
        toast.success(`Fonction ${obj.name} créé`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleUpdateFunctionsCategories = (id, obj) => {

    updateFunctionsCategories(obj)
      .then(() => {
        getFunctionsCategories();
        setEditing(false)
        toast.success(`Fonction ${obj.name} mise à jour`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleDeleteFunctionsCategories = id => {
    setEditing(false)
    setFunctionsCategories(functionsCategories.filter(obj => obj.id !== id))
  }
  
  const editRow = obj => {
    setEditing(true)
    setCurrentFunctionsCategories({ id: obj.id, name: obj.name })
  }
  return (
    <React.Fragment>
      {isLoading &&
        <div className="container " >
          <div className="div-header pb-4">
            <h1 className="pb-4">Gestion des catégories de fonctions</h1>
          </div>
          <div className="div-quotestatus">

            <div >
              {!_.isEmpty(functionsCategories) ? (
                <FunctionsCategoriesTable functionsCategories={functionsCategories} editRow={editRow} handleDeleteFunctionsCategories={handleDeleteFunctionsCategories} />
              ) : (
                <p className="message">Aucune fonction disponible. Veuillez ajouter des fonctions.</p>
              )}
            </div>

            <div className="main-form">
              {editing ? (
                <Fragment>
                  <h2>Editer une fonction </h2>
                  <EditFunctionsCategoriesForm
                    editing={editing}
                    setEditing={setEditing}
                    currentFunctionsCategories={currentFunctionsCategories}
                    handleUpdateFunctionsCategories={handleUpdateFunctionsCategories}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <h2>Ajouter une fonction</h2>
                  <AddFunctionsCategoriesForm handleAddFunctionsCategories={handleAddFunctionsCategories} />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default FunctionsCategoriesPage;

