import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';
import Title from 'components/generics/Title';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { confirmCreds } from 'services/API/User';

import { validLogin, validPassword } from 'utils';


const ConfirmCredentials = () => {
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState(
    {
      login: '',
      password: '',
      token: location.state.token
    });




  function validate(formData) {
    let isErr = false;

    // if (!isErr && !validLogin.test(formData['login'])) {
    //   isErr = true;
    //   toast.error('Le login n\'est pas renseigné');
    // }

    if (formData.password !== formData.passwordVerify) {
      isErr = true;
      toast.error('Les mots de passe ne sont pas identique');
    }
    if (!isErr && !validPassword.test(formData['password'])) {
      isErr = true;
      toast.error('Le mot de passe n\'est pas correct.');
    }

    return isErr;

  }
  const handleOnSubmit = (event) => {
    if (event) event.preventDefault();
    console.log(formData);
    if (!validate(formData)) {

      confirmCreds(formData)
        .then(() => {
          toast.success(`Membre ${formData.usName} mis à jour`)
          history.push('/login');
        })
        .catch((err) => toast.error(err.response.data['message']));
    }

  };

  function handleInputChange(name, value) {

    setFormData({ ...formData, [name]: value });
  }

  return (
    <React.Fragment>
      <div className="container main-form" >
        <Title
          className="div-header"
          text="Saisissez vos identifiants"
        />

        <div className="div-form">
          <form onSubmit={handleOnSubmit}>

            <InputLabel
              name="login"
              value={formData.login}
              onChange={(e) => {
                handleInputChange('login', e);
              }}
              placeholder="Identifiant"
              label="Identifiant"
              defaultStyle='login'
            />
            <InputLabel
              name="password"
              value={formData.password}
              onChange={(e) => {
                handleInputChange('password', e);
              }}
              placeholder="Mot de passe"
              label="Mot de passe"
              defaultStyle='login'
              type='password'
            />
            <InputLabel
              name="passwordVerify"
              value={formData.passwordVerify}
              onChange={(e) => {
                handleInputChange('passwordVerify', e);
              }}
              placeholder="Vérification mot de passe"
              label="Vérification mot de passe"
              defaultStyle='login'
              type='password'
            />

            <Button defaultStyle='d-block' type="submit" text="Enregistrer" />
          </form>
        </div>

      </div>
    </React.Fragment >
  );
}

export default ConfirmCredentials;
