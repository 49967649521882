import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import DataControllerForm from './DataControllerForm';

import { updateDataController } from 'services/API/Clients';


const EditDataController = ({clientId, functionsCat, dataController}) => {
    const history = useHistory();

    const handleOnSubmit = (dc) => {
        dc["clientId"] = clientId;

        updateDataController(dc)
            .then(() => {
                toast.success(`Représentant légal ${dc.cdcName} mis à jour`);
                history.push('/clients/all');
            })
            .catch((err) => toast.error(err.response.data['message']));
    };

    return (
        <div>
            {functionsCat && !_.isEmpty(dataController) &&
                <DataControllerForm 
                functionsCat={functionsCat} 
                formData={dataController} 
                isEditMode={true} 
                handleOnSubmit={handleOnSubmit} />
            }
        </div>
    );
};

export default EditDataController;
