import React from 'react';
import PropTypes from 'prop-types';
import { v1 } from 'uuid';

const TableHead = ({ items = [] }) => (
  <thead>
    <tr>
      {items.map(item => (
        <th key={v1()} scope='col' data-sortable="true" data-field={item}><span>{item}</span></th>
      ))}
    </tr>
  </thead>
);

TableHead.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableHead;