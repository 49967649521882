import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import _ from 'lodash';

import AddDataController from './AddDataController';
import EditDataController from './EditDataController';

import { getAllFunctionsCategories} from 'services/API/Clients';

export default function ClientsDataControllerPage() {
    const location = useLocation();
    let client = location.state.client;
    let isEditMode = client.clientsDataController ? true : false;

    const [functionsCategories, setFunctionsCategories] = useState(null);

    const getFunctionsCategories= async () => {
        await getAllFunctionsCategories()
          .then((g) => {
              setFunctionsCategories(g.data.data);
          })
          .catch(() => toast.error('Impossible de charger les fonctions '));
    }

    useEffect(() => {
        getFunctionsCategories();
    }, [ ]);


    return (
        <React.Fragment>
            <div>
            {functionsCategories && 
                (
                    isEditMode ?
                        <EditDataController 
                        clientId={client.id} 
                        functionsCat={functionsCategories} 
                        dataController={client.clientsDataController}  />
                    :
                        <AddDataController 
                        clientId={client.id} 
                        functionsCat={functionsCategories}  />
                )
            }
            </div>
        </React.Fragment>
       

    )
}
