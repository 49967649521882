import React from 'react';

import { NavLink } from 'react-router-dom';
import Button from 'components/generics/Button';

import './buttonsForm.style.scss';
// import Save from 'components/icons/Save';
// import Back from 'components/icons/Back';

const ButtonsForm = ({ route, isSaveButton, isBackButton }) => {
  return (
    <div className="div-button">

      {isSaveButton &&
        <Button
          type="submit"
          defaultStyle="btn btn-primary"
          text="Enregistrer"
          textColor="primary"
          backgroundColor="btn btn-primary"
        />
      }

      {isBackButton &&
        <NavLink to={route}
          // variant="secondary"
          className="backbutton link btn btn-secondary"
          activeClassName="active">
          Retour
        </NavLink>
      }
    </div>
  );
}

export default ButtonsForm;
