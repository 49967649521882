import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

// import TableHead from 'components/generics/Table/TableHead';
// import { NavLink } from 'react-router-dom';

import ClientComments from './Show/ClientComments';

import './showClient.style.scss';

import { getClient, getAllFunctionsCategories } from 'services/API/Clients';
import Edit from 'components/icons/Edit';
import ClientDivers from './Show/ClientDivers';
import ClientContacts from './Show/ClientContacts';
import ClientCoordinate from './Show/ClientCoordinate';
import ClientDataController from './Show/ClientDataController';
import ClientDocuments from './Show/ClientDocuments';
import Title from 'components/generics/Title';

export default function ShowClient() {

    const location = useLocation();
    let clientId = location.state.client.id ? location.state.client.id : null;
    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowComments, setIsShowComments] = useState(true);
    const [isShowDivers, setIsShowDivers] = useState(true);
    const [functionsCategories, setFunctionsCategories] = useState(null);

    const getFunctionsCategories = async () => {
        await getAllFunctionsCategories()
            .then((g) => {
                setFunctionsCategories(g.data.data);
                setIsLoading(true);
            })
            .catch(() => toast.error('Impossible de charger les status des devis'));
    }
    useEffect(() => {
        getFunctionsCategories();
    }, []);
    useEffect(() => {
        if (!isLoading && clientId !== null) {
            getClient(clientId)
                .then((resp) => {
                    // console.log(resp.data.data);
                    setClient(resp.data.data);
                    setIsLoading(true);
                })
                .catch((err) => toast.error(err.data));
        }
    }, [clientId, isLoading]);



    const handleRemoveContact = (id) => {
        //setClients(clients.filter((client) => client.id !== id));
        //TODO
    };

    return (
        <React.Fragment>
            {
                client != null &&
                <div className="show-client">
                    <Title 
                    text={client.clName} 
                    isBackButton={true}
                    routeBack="/clients/all"
                    />
                    <div className='container-fluid p-3'>
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-4'>
                                <ClientCoordinate client={client} />
                            </div>
                             <div className='col-12 col-lg-4 py-3 p-lg-0'>
                                <ClientDataController client={client} />
                            </div>                            
                            <div className='col-12 col-lg-4'>
                                <article className="card" >
                                    <div className="card-body">
                                        <span className="d-flex justify-space-between">
                                            <h4 className="card-title text-center pb-3 flex-2">Divers</h4>
                                            <button className="btn-edit m-0 p-0" onClick={() => setIsShowDivers(false)}>
                                                <Edit width={42} height={42} />
                                            </button>
                                        </span>
                                        <ClientDivers client={client} isShowDivers={isShowDivers} />
                                    </div>
                                </article>
                            </div>                       
                        </div>
                        <div className='row pb-3'>
                            <div className='col-12'>
                            <ClientContacts client={client}
                            functionsCategories={functionsCategories}
                            handleRemoveContact={handleRemoveContact} />
                            </div>
                        </div>
                        <div className='row pb-3'>
                            <div className='col-12'>
                            <ClientDocuments client={client} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <article className="card" >
                                    <div className="card-body">
                                        <span className="d-flex justify-space-between">
                                            <h4 className="card-title text-center pb-3 flex-2">Informations complémentaires</h4>
                                            <button className="btn-edit" onClick={() => setIsShowComments(false)}>
                                                <Edit width={40} height={40} />
                                            </button>
                                        </span>
                                        <ClientComments client={client} isShowComments={isShowComments} />
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </React.Fragment>



    )
}

