import React from 'react';

import QuoteTitle from './QuoteTitle';
import QuoteHeader from './QuoteHeader';
import QuoteInfo from './QuoteInfo';
import QuoteFooter from './QuoteFooter';
import QuoteSummary from './QuoteSummary';

import logo from 'assets/images/logo/logo_couleur.jpg';

import "./showQuote.css";

const FROM = {
    name: "Frédéric FABREGUE",
    email: "ffabregue@solutions909.fr",
    phone: "07.67.98.59.88",
    address: "6 rue du Capricorne",
    zipcode: "13320",
    city: "BOUC BEL AIR",
    website: "http://solutions909.fr"
};

const QuoteDocument = ({quote}) => {
    return (
        <div className="invoice-6 invoice-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="invoice-inner-6" id="invoice_wrapper">
                            <QuoteHeader logo={logo} />
                            <QuoteTitle quoteNumber={quote.quoteNumber} quoteDate="12/09/21" />
                            <QuoteInfo from={FROM} client={quote.client} contact={quote.contact} dueDate="24/08/2021" />
                            {quote.quoteProducts && <QuoteSummary quoteProduct={quote.quoteProducts} quote={quote}/>}
                            <QuoteFooter />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuoteDocument;
