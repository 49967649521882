import React from 'react';
import PropTypes from 'prop-types';

const Prospect = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M8 12c2.28 0 4-1.72 4-4s-1.72-4-4-4s-4 1.72-4 4s1.72 4 4 4zm0-6c1.178 0 2 .822 2 2s-.822 2-2 2s-2-.822-2-2s.822-2 2-2zm1 7H7c-2.757 0-5 2.243-5 5v1h2v-1c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v1h2v-1c0-2.757-2.243-5-5-5zm9.364-10.364L16.95 4.05C18.271 5.373 19 7.131 19 9s-.729 3.627-2.05 4.95l1.414 1.414C20.064 13.663 21 11.403 21 9s-.936-4.663-2.636-6.364z"/>
        <path color={color} fill={fill} d="M15.535 5.464L14.121 6.88C14.688 7.445 15 8.198 15 9s-.312 1.555-.879 2.12l1.414 1.416C16.479 11.592 17 10.337 17 9s-.521-2.592-1.465-3.536z"
      />
    </g>
  </svg>
);

Prospect.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Prospect.defaultProps = {
  color: '#000',
  fill: '#FFA500',
  width: 18.605,
  height: 18.605,
};

export default Prospect;