import React, { useEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// import Edit from 'components/icons/Edit';
import SelectLabel from 'components/generics/SelectLabel';

import { convertIdClients } from 'utils';
import { getAllUsers } from 'services/API/User';
import { updateManager } from 'services/API/Clients';
import ButtonsForm from 'components/generics/ButtonsForm';

import "./clientdivers.style.scss";

const ClientDivers = ({ client, isShowDivers }) => {
    const history = useHistory();
    const [formData, setFormData] = useState(client);
    const [users, setUsers] = useState(null);



    useEffect(() => {

        const getUsers = async () => {
            await getAllUsers()
                .then((g) => {
                    setUsers(g.data.data);
                })
                .catch((err) => {
                    toast.error("impossible : " + err.response.data['message'])
                }
                );

        }
        getUsers();
    }, []);

    const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

    const handleOnSubmit = (event) => {
        if (event) event.preventDefault();


        updateManager(formData)
            .then(() => {
                toast.success(`Client ${client.clName} mis à jour`)
                history.push(`/clients/all`);
            })
            .catch((err) => toast.error(err.response.data['message']));

    };


    return (
        <section className="mt-10 mb-5">
            {users &&
                <form className="form-client client-various" onSubmit={handleOnSubmit}>

                    <SelectLabel
                        defaultStyle='secondary'
                        label="Gestionnaire"
                        name='userClients'
                        disabled={isShowDivers}
                        onChange={(e) => {
                            updateForm('userClients', convertIdClients(+e, users));
                        }}
                        options={users}
                        value={formData.userClients.id}
                        itemLabel='usName'
                        itemValue='id'
                        
                    />

                    {!isShowDivers &&
                        <ButtonsForm route={`/`} isSaveButton={true} />
                    }
                </form >
            }
        </section>






    );
}

export default ClientDivers;
