import ButtonsForm from 'components/generics/ButtonsForm';
import Title from 'components/generics/Title';
import React from 'react';
import { useLocation } from "react-router-dom";
// import { toast } from 'react-toastify';

// import TableHead from 'components/generics/Table/TableHead';
// import { NavLink } from 'react-router-dom';
import { formatDate } from 'utils';

import './showUser.style.scss';



// import { getAllUsers } from 'services/API/User';

export default function ShowUser() {
    const location = useLocation();
    let user = location.state.user;


    return (
        <React.Fragment>
            {
                user != null &&
                <div className='div-showuser' >
                    <Title
                    text= "Fiche collaborateur"/>
                    
                    <div className="div-list">
                        <article className="card">
                            <div className="content">
                                <table >
                                    <tbody>
                                        <tr>
                                            <th>Login</th>
                                            <td>{user.login}</td>
                                        </tr>
                                        <tr>
                                            <th>Roles</th>
                                            <td>{user.roles ? JSON.stringify(user.roles) : ''}</td>
                                        </tr>

                                        <tr>
                                            <th>Nom</th>
                                            <td>{user.usName}</td>
                                        </tr>
                                        <tr>
                                            <th>Prénom</th>
                                            <td>{user.usFirstName}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{user.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Email personnel</th>
                                            <td>{user.usPrivateEmail ? `${user.usPrivateEmail}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Téléphone</th>
                                            <td>{user.usPhone ? `${user.usPhone}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Téléphone Personnel</th>
                                            <td>{user.usPrivatePhone ? `${user.usPrivatePhone}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Adresse</th>
                                            <td>{user.usAddress ? `${user.usAddress}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Code Postal</th>
                                            <td>{user.usZipcode ? `${user.usZipcode}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Ville</th>
                                            <td>{user.usCity ? `${user.usCity}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Date de naissance</th>
                                            <td>{user.usBirthdate ? `${user.usBirthdate}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Lieu de naissance</th>
                                            <td>{user.usBirthplace ? `${user.usBirthplace}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Groupe</th>
                                            <td>{user.usGroup ? `${user.usGroup}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Date de création</th>
                                            <td>{user.usCreatedAt ? formatDate(user.usCreatedAt) : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Date de fin de droit</th>
                                            <td>{user.usBlockedAt ? formatDate(user.usBlockedAt) : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Compte activé (log)</th>
                                            <td>{user.isVerified ? 'Oui' : 'Non'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </article>
                    </div>
                    <br />
                    <ButtonsForm route={`/users`} isBackButton={true}/>
                </div>
            }

        </React.Fragment>
    )

}
