import React from 'react';

import { NavLink } from 'react-router-dom';

import Edit from 'components/icons/Edit';
import Circle from 'components/icons/Circle';
import Prospect from 'components/icons/Prospect';
import Client from 'components/icons/Client'

import "./clientcoordinate.style.scss";

const ClientCoordinate = ({client}) => {
    return (
        <article className="card card-coordinate" >
        <div className="card-body table-responsive">
            <span className="d-flex justify-space-between">
                <h4 className="card-title text-center pb-3 flex-2">Coordonnées</h4>
                <NavLink to={{ pathname: `/client/edit/${client.slug}`, state: { client: client } }} activeClassName="active"><Edit /></NavLink>
            </span>
            <table className='w-100'>
                <tbody>
                    <tr>
                        <th className='text-start'>Code client</th>
                        <td className='text-start'>{client.clClientCode?client.clClientCode : "-" }</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Code organisme</th>
                        <td className='text-start' >{client.clOrgCode? client.clOrgCode : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Nom</th>
                        <td className='text-start'>{client.clName? client.clName : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Adresse</th>
                        <td className='text-start'>{client.clAddress? client.clAddress : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Code postal</th>
                        <td className='text-start'>{client.clZipcode? client.clZipcode : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Ville</th>
                        <td className='text-start'>{client.clCity? client.clCity : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Email</th>
                        <td className='text-start'>{client.clEmail? client.clEmail : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Téléphone</th>
                        <td className='text-start'>{client.clPhone? client.clPhone : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Site Web</th>
                        <td className='text-start'>{client.clWebsite? client.clWebsite : "-"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Statut</th>
                        
                        <td className='text-start'>{client.clStatus === true ? "ACTIF" : "INACTIF"}</td>
                    </tr>
                    <tr>
                        <th className='text-start'>Catégorie</th>
                        
                        <td className='text-start'>{client.clProspectFlag === true ? "CLIENT" : "PROSPECT"}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </article>
    );
}

export default ClientCoordinate;
