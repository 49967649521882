import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from 'providers/Auth/useAuth';
import ProfilForm from './ProfilForm';
import { toast } from 'react-toastify';
import { getUser, editProfil } from 'services/API/User';




function ProfilePage() {
  const { user } = useAuth();
  const history = useHistory();
  const [isShowProfil, setIsShowProfil] = useState(true);
  const [showUser, setShowUser] = useState(null);

  useEffect(() => {
    const getProfileById = async () => {
      await getUser(user.id)
        .then((resp) => {
          setShowUser(resp.data.data);
        })
        .catch((err) => toast.error(err.data));
    }
    getProfileById();

  }, [user]);

  const handleOnSubmit = (formData) => {
    console.log(formData);
    editProfil(formData)
    .then(() => {
          toast.success(`Profil ${formData.usName} mis à jour`)
          history.push(`/`);
      })
      .catch((err) => toast.error(err.response.data['message']));
}


  return (

    <div className="container main-form" >

    <div className="div-header">
        <h1>Mon profil</h1>
        <button id="isEdit"
            onClick={() => {setIsShowProfil(false); 
            document.querySelector("#isEdit").style.display = 'none';
          }}
            className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
          >
            Editer
          </button>
    </div>

      {showUser &&
        <div>
        <ProfilForm user={showUser} isShowProfil={isShowProfil} handleOnSubmit={handleOnSubmit}></ProfilForm>


        </div>
      }

    </div>
  );
};

export default ProfilePage;