import React, { useEffect,  useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ClientForm from './ClientForm';

import { addClient, getAllTypeOrganism } from 'services/API/Clients';


const AddClient = () => {
  const history = useHistory();
  const [organismTypes, setOrganismTypes] = useState(null);

  useEffect(() => {
    const getTypeOrganism = async () => {
          await getAllTypeOrganism()
            .then((g) => {
              setOrganismTypes(g.data.data);
            })
            .catch(() => toast.error('Impossible de charger les types d\'organisme'));
      }
      getTypeOrganism();
  }, [ ]);

  const handleOnSubmit = (client) => {
    
    addClient(client)
      .then(() => {
          toast.success(`Client ${client.clName} créé`)
          history.push('/clients/all');
      })
      .catch((err) => toast.error(err.response.data['message']));
  };


  return (
    <div>
      {organismTypes && 
      <ClientForm organismTypes={organismTypes} handleOnSubmit={handleOnSubmit} />
      }
      
    </div>
  );
};

export default AddClient;
