import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/generics/Select';
import './selectLabel.style.scss';

const SelectLabel = ({

    name,
    onChange,
    options,
    value,
    required,
    itemLabel,
    itemValue,
    color,
    label,
    defaultStyle,
    disabled
}) => (
    <label className={defaultStyle} htmlFor={name}>
        {label}
        <Select
            name={name}
            onChange={onChange}
            options={options}
            value={value}
            itemLabel={itemLabel}
            itemValue={itemValue}
            className={defaultStyle}
            disabled={disabled}
            required={required}
            style={color}
            aria-label={name}

        />
    </label>
);

SelectLabel.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    defaultStyle: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    color: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,

            value: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]),
        })
    ),
    itemLabel: PropTypes.string.isRequired,
    itemValue: PropTypes.string.isRequired

};

SelectLabel.defaultProps = {
    defaultStyle: '',
    disabled: false,
    name: '',
    required: false,
    color: '',
    placeholder: '',
    options: [],
    value: ''
};

export default SelectLabel;
