import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ProductsTable from './ProductsTable';
import AddProductsForm from './AddProductsForm';
import EditProductsForm from './EditProductsForm';
import { getAllProducts, addProducts, updateProducts } from 'services/API/Quotes';

import 'assets/styles/index.scss';

import './products.style.scss';

const ProductsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const initialFormState = { id: null, name: '', description: '' }

  // Setting state
  const [currentProducts, setCurrentProducts] = useState(initialFormState)
  const [editing, setEditing] = useState(false)

  const getProducts = async () => {
    await getAllProducts()
      .then((g) => {
        setProducts(g.data.data);
        setIsLoading(true);
      })
      .catch(() => toast.error('Impossible de charger les status des devis'));
  }

  useEffect(() => {
    getProducts();
  }, []);

  // CRUD operations
  const handleAddProducts = sts => {
    addProducts(sts)
      .then(() => {
        getProducts();
        toast.success(`produit  ${sts.title} créé`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleUpdateProducts = (id, sts) => {

    updateProducts(sts)
      .then(() => {
        getProducts();
        setEditing(false)
        toast.success(`produit  ${sts.title} mis à jour`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleDeleteProducts = id => {
    setEditing(false)

    setProducts(products.filter(sts => sts.id !== id))
  }
  const editRow = sts => {
    setEditing(true)

    setCurrentProducts({ id: sts.id, title: sts.title, content: sts.content, priceUnit: sts.priceUnit })
  }
  return (
    <React.Fragment>
      {isLoading &&
        <div className="container " >
          <div className="div-header pb-4">
            <h1 className="pb-4">Gestion des produits des devis</h1>
          </div>
          <div className="div-quotestatus">

            <div >
              {!_.isEmpty(products) ? (
                <ProductsTable products={products} editRow={editRow} handleDeleteProducts={handleDeleteProducts} />
              ) : (
                <p className="message">Aucun produit disponible. Veuillez ajouter des produits pour les devis.</p>
              )}
            </div>

            <div className="main-form">
              {editing ? (
                <Fragment>
                  <h2>Editer un produit </h2>
                  <EditProductsForm
                    editing={editing}
                    setEditing={setEditing}
                    currentProducts={currentProducts}
                    handleUpdateProducts={handleUpdateProducts}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <h2>Ajouter un produit</h2>
                  <AddProductsForm handleAddProducts={handleAddProducts} />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default ProductsPage;

