import React from 'react';

import Card from 'components/generics/Card';
import Client from 'components/icons/Client';
import Invoice from 'components/icons/Invoice';

import 'assets/styles/index.scss';

const GeneralPage = () => {
    return (

        <div className="container">
            {/* AJOUTER LE BREADCRUMB */}
            <div className="row div-header">
                <h1>Configuration générale</h1>
            </div>

            <div className="div-cards">
                <Card
                    title="Statuts des devis"
                    route={`/configuration/generale/quote-status`}
                    subtitle="Tous les états d'un devis"
                    icon={<Client width="64" height="64" fill="#f6ae2d" />}
                />


                <Card
                    title="Produits des devis"
                    route={`/configuration/generale/products`}
                    subtitle="Tous les produits nécessaires d'un devis"
                    icon={<Invoice width="64" height="64" fill="#f6ae2d" />}
                />

                <Card
                    title="Catégories de fichiers"
                    route={`/configuration/generale/files-cat`}
                    subtitle="Toutes les catégories des documents client"
                    icon={<Client width="64" height="64" fill="#f6ae2d" />}
                />

                <Card
                    title="Fonctions"
                    route={`/configuration/generale/functions-cat`}
                    subtitle="Toutes les catégories des fonctions client"
                    icon={<Client width="64" height="64" fill="#f6ae2d" />}
                />

                <Card
                    title="Types d'organisme"
                    route={`/configuration/generale/type-org`}
                    subtitle="Toutes les types d'organisme client"
                    icon={<Client width="64" height="64" fill="#f6ae2d" />}
                />


            </div>
        </div>

    );
}

export default GeneralPage;

