import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import JWTService from 'services/JWT';
import useAuth from 'providers/Auth/useAuth';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';
import InputPassword from 'components/generics/InputPassword';


const DEFAULT_FORM = { username: '', password: '' };

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { setUser } = useAuth();

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    JWTService.signInWithCredentials(formData.username, formData.password)
      .then((response) => {
        setLoading(false);
        toast.success('Bon retour parmi nous !');
        setUser(response.data.data);
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        setLoading(false);
        if (err.code && err.code === 401) {
          toast.error('Identifiants incorrects')
          updateForm('password', '');
        } else {
          toast.error('Erreur inconnue');
          setFormData(DEFAULT_FORM);
        }
      });
  };

  

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <form className="container py-3" onSubmit={onSubmit}>
        <div className="row text-center">
          <div className="col">
            <InputLabel
              name="username"
              value={formData.username}
              onChange={(e) => {
                updateForm('username', e);
              }}
              // placeholder="Identifiant"
              label="IDENTIFIANT"
              defaultStyle='login'
              disabled={loading}
            />
          </div>
        </div>

        <div className="row text-center">
          <div className="col">
            <InputPassword
              name="password"
              value={formData.password}
              type="password"
              onChange={(e) => {
                updateForm('password', e);
              }}
              // placeholder="Mot de passe"
              defaultStyle='login'
              label="MOT DE PASSE"
              disabled={loading}
              onClick={() => {
                setPasswordShown(!passwordShown);
              }}
              
              passwordShown={passwordShown}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Link to='/forgotPassword'>Mot de passe oublié ?</Link>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Button backgroundColor="#f6ae2d" defaultStyle='btn btn-primary d-block' type="submit" text="Connexion" disabled={loading} />
          </div>
        </div>

      </form>
    </>
  );
};

export default LoginForm;
