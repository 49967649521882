import React from 'react';
import PropTypes from 'prop-types';


const Delete = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z M9 10h2v8H9zm4 0h2v8h-2z" 
      />
    </g>
  </svg>
);

Delete.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Delete.defaultProps = {
  color: '#FF0000',
  fill: '#FF0000',
  width: 32,
  height: 32,
};

export default Delete;