export default function QuoteInfo({ from, client, contact, dueDate }) {
    return (
      <>
        <div className="invoice-info border-bottom">
            <div className="row">
                <div className="col-sm-6 mb-30">
                    <div className="invoice-number">
                        <h4 className="inv-title-1">De</h4>
                        <address className="invo-addr-1">
                            <strong>{from.name}</strong> <br />
                            {from.email} <br />
                            {from.phone} <br />
                            {from.address}, <br />{from.zipcode} {from.city}
                        </address>
                        <p>{from.website}</p>
                    </div>
                </div>
                <div className="col-sm-6 mb-30">
                    <div className="invoice-number text-end">
                        <h4 className="inv-title-1">A l'attention de</h4>
                        <p><strong>{client.clName} </strong></p>
                        <address className="invo-addr-1">
                            <strong>{contact.ccName}</strong> <br />
                            {/* {to.email} <br /> */}
                            {client.clAddress}, <br />{client.clZipcode} {client.clCity}
                        </address>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 mb-30">
                    <h4 className="inv-title-1">Date</h4>
                    <p className="inv-from-1">Due Date: {dueDate}</p>
                </div>
            </div>
        </div> 
      </>
    )
  }