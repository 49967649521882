import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import QuoteForm from './Quote/QuoteForm';
import { addQuote } from 'services/API/Quotes';

const AddQuote = () => {
    const history = useHistory();

    const handleOnSubmit = (quote) => {
        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
          }
        addQuote(quote, config)
            .then(() => {
                toast.success(`Devis ${quote.quoteNumber} créé`)
                history.push('/devis');
            })
            .catch((err) => toast.error(err.response.data['message']));
        };
  
    return (
        <>
            <QuoteForm handleOnSubmit={handleOnSubmit} />
        </>

    )
}

export default AddQuote;
