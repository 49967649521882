import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import QuoteStatusTable from './QuoteStatusTable';
import AddQuoteStatusForm from './AddQuoteStatusForm';
import EditQuoteStatusForm from './EditQuoteStatusForm';
import { getAllQuoteStatus, addQuoteStatus, updateQuoteStatus } from 'services/API/Quotes';

import 'assets/styles/index.scss';

import './quotestatus.style.scss';

const QuoteStatusPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const initialFormState = { id: null, name: '', description: '' }

  // Setting state
  const [currentQuoteStatus, setCurrentQuoteStatus] = useState(initialFormState)
  const [editing, setEditing] = useState(false)

  const getQuoteStatus = async () => {
    await getAllQuoteStatus()
      .then((g) => {
        setQuoteStatus(g.data.data);
        setIsLoading(true);
      })
      .catch(() => toast.error('Impossible de charger les status des devis'));
  }

  useEffect(() => {
    getQuoteStatus();
  }, []);

  // CRUD operations
  const handleAddQuoteStatus = obj => {
    addQuoteStatus(obj)
      .then(() => {
        getQuoteStatus();
        toast.success(`Statut  ${obj.name} créé`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleUpdateQuoteStatus = (id, obj) => {

    updateQuoteStatus(obj)
      .then(() => {
        getQuoteStatus();
        setEditing(false)
        toast.success(`Statut  ${obj.name} mis à jour`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleDeleteQuoteStatus = id => {
    setEditing(false)
    setQuoteStatus(quoteStatus.filter(obj => obj.id !== id))
  }
  
  const editRow = obj => {
    setEditing(true)
    setCurrentQuoteStatus({ id: obj.id, name: obj.name, description: obj.description })
  }
  return (
    <React.Fragment>
      {isLoading &&
        <div className="container " >
          <div className="div-header pb-4">
            <h1 className="pb-4">Gestion des statuts des devis</h1>
          </div>
          <div className="div-quotestatus">

            <div >
              {!_.isEmpty(quoteStatus) ? (
                <QuoteStatusTable quoteStatus={quoteStatus} editRow={editRow} handleDeleteQuoteStatus={handleDeleteQuoteStatus} />
              ) : (
                <p className="message">Aucun statut disponible. Veuillez ajouter des statuts pour les devis.</p>
              )}
            </div>

            <div className="main-form">
              {editing ? (
                <Fragment>
                  <h2>Editer un statut </h2>
                  <EditQuoteStatusForm
                    editing={editing}
                    setEditing={setEditing}
                    currentQuoteStatus={currentQuoteStatus}
                    handleUpdateQuoteStatus={handleUpdateQuoteStatus}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <h2>Ajouter un statut</h2>
                  <AddQuoteStatusForm handleAddQuoteStatus={handleAddQuoteStatus} />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default QuoteStatusPage;
