import React from 'react';
// import IconButton from 'components/generics/IconButton';
// import Edit from 'components/Home/Edit';
// import Delete from 'components/icons/Delete';

import {formatDateTime} from "utils";

const LogsTable = props => (
    <table className="table" data-toggle="table" data-search="true" data-sort-name="CreatedAt" data-sort-order="desc">

        <thead className="text-center">
            <tr>
                <th className="d-md-table-cell" data-sortable="true" data-field="user">Utilisateur</th>

                <th>Message</th>
                {/* <th>Context</th> */}
                <th>Level</th>
                <th>LevelName</th>
                <th>Extra</th>

                <th className="d-md-table-cell" data-sortable="true" data-field="CreatedAt">Date</th>

            </tr>
        </thead>
        <tbody>
            {props.logs.length > 0 ? (
                props.logs.map(item => (
                    <tr key={item.id}>
                        <td>{item.user}</td>
                        <td>{item.message}</td>
                        {/* <td>{item.context ? item.context : '' }</td> */}
                        <td>{item.level }</td>
                        <td>{item.levelName }</td>
                        {/* <td>{item.extra ? item.extra : '' }</td> */}
                        <td></td>
                        <td>{item.createdAt ? `${formatDateTime(item.createdAt.date)}` : '' }</td>

                        
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={3}>Pas de catégories de fichier</td>
                </tr>
            )}
        </tbody>
    </table>
)


export default LogsTable;

