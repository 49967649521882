import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 1024 1024">
    <g>
        <path color={color}
        fill={fill} d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
        <path color={color}
        fill={fill} d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />



    </g>
  </svg>
);

Plus.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Plus.defaultProps = {
  color: '#0e384d',
  fill: '#0e384d',
  width: 32,
  height: 32,
};

export default Plus;
