import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

import ContactForm from './ContactForm';
import { addContact } from 'services/API/Clients';

const AddContact = () => {
  const history = useHistory();
  const location = useLocation();
  const clientId = location.state.client.id;
  const functionsCategories = location.state.functionsCategories;
  let contact = {
    ccName: '',
    ccFirstname: '',
    ccPhone: '',
    ccEmail: '',
    functionsCategories: functionsCategories ? functionsCategories[0] : {}
  };

  const handleOnSubmit = (contact) => {
    contact["clientId"] = clientId;
    addContact(contact)
      .then(() => {
        toast.success(`Contact créé`)
        history.push('/clients/all');
      })
      .catch((err) => toast.error(err.response.data['message']));
  };

  return (
    <React.Fragment>
      {functionsCategories &&
        <ContactForm 
        isEditMode={false} 
        contact={contact} 
        functionsCategories={functionsCategories} 
        handleOnSubmit={handleOnSubmit} />
      }
    </React.Fragment>

  )
};

export default AddContact;
