import React from 'react';

import 'assets/styles/index.scss';
import Title from 'components/generics/Title';

export default function SettingsPage() {
    return (
        <div >
            <Title
                className="div-header"
                text="Page de configuration"
                 />
        </div>
    )
}
