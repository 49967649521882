import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// import _ from 'lodash';

import UsersHome from 'components/pages/User/Home';

import { getAllUsers } from 'services/API/User';

import 'assets/styles/index.scss';
import './user.style.scss';

const UsersPage = () => {
  const [users, setUsers] = useState(null);
  // const [managedBy, setManagedBy] = useState(user.id);
  // const [members, setMembers] = useState(null);

  // const [isLoading, setIsLoading] = useState (false);
  // const isMounted = useRef(false);

  useEffect(() => {

    const getUsers = async () => {
      await getAllUsers()
        .then((g) => {
          // console.log(g);
          setUsers(g.data.data);
        })
        .catch((err) => {
          toast.error("impossible : " + err.response.data['message'])
        }
        );

    }  
    getUsers();
  }, []);

return (
  <React.Fragment>
  <div>
    {users && 
    <UsersHome user={users} />
    }

  </div>

  </React.Fragment>
);
};

export default UsersPage;
