import React from 'react';
import PropTypes from 'prop-types';

const Back = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        fill={fill}
        color={color}
        d="M21 10.5c0 4.14-3.36 7.5-7.5 7.5H11v4l-7-6l7-6v4h2.5c1.93 0 3.5-1.57 3.5-3.5S15.43 7 13.5 7H6V3h7.5c4.14 0 7.5 3.36 7.5 7.5Z"
      />
    </g>
  </svg>
);

Back.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Back.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width:32,
  height: 32,
};

export default Back;
