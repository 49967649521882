/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';

export default function HomePage() {
    // const history = useHistory();
    const { user } = useAuth();

    // console.log(user);
    return (user ? (
        <div className="content">
            <h2 className="text-center">Bienvenue <strong>{user.usName}</strong> sur admin machine !!!</h2>
        </div>)
        :
        (<Redirect to='/login' />)
    )
}
