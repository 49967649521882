import React from 'react';

const SupportPage = () => {
    return (
        <React.Fragment>

            <div className="div-header">
                <h1 className="text-center">Gestion des supports</h1>
            
            </div>
        
  
      </React.Fragment>
    );
}

export default SupportPage;
