import React, { useState, useEffect } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';
// import ButtonsForm from 'components/generics/ButtonsForm';


const EditFilesCatForm = props => {
    const [ filesCat, setFilesCat ] = useState(props.currentFilesCat)
  
    useEffect(
      () => {
        setFilesCat(props.currentFilesCat)
      },
      [ props ]
    )
    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]
  
    const handleInputChange = (name, value) => {
        setFilesCat({ ...filesCat, [name]: value })
    }
  
    return (
      <form className="form-client"
        onSubmit={event => {
          event.preventDefault()
          props.handleUpdateFilesCat(filesCat.id, filesCat)
        }}
      >

      <InputLabel
				name="name"
				type="text"
				value={filesCat.type}
				defaultStyle='secondary'
				label="Type de fichier"
				onChange={(e) => { handleInputChange('type', e); }}/>
			<InputLabel
				name="description"
				type="text"
				value={filesCat.description}
				defaultStyle='secondary'
				label="Description"
				onChange={(e) => { handleInputChange('description', e); }}/>

        <div className="div-button">
          <Button
            type="submit"
            defaultStyle="btn btn-primary"
            text="Enregistrer"
            textColor="primary"
          />
          <Button 
            type="button"
            text="Annuler"  
            onClick={() => props.setEditing(false)} 
            defaultStyle="btn btn-secondary"
          />
        </div>
        {/* <ButtonsForm route={`/users`} isSaveButton={true} isBackButton={true}/> */}
      </form>
    )
  }

export default EditFilesCatForm;
