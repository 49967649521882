import React from 'react';
import { NavLink } from 'react-router-dom';
// import TableHead from 'components/generics/Table/TableHead';
import Edit from 'components/icons/Edit';
// import UserPlus from 'components/icons/UserPlus';
// import Delete from 'components/icons/Delete';



const ClientDataController = ({ client }) => {
    console.log(client);
    return (
        <article className="card card-datacontroller">
            <div className="card-body table-responsive">
                <span className="d-flex justify-space-between">
                    <h4 className="card-title text-center pb-3 flex-2">Représentant légal</h4>
                    <NavLink to={{ pathname: `/client/${client.slug}/dc`, state: { client: client } }} activeClassName="active" ><Edit /></NavLink>
                </span>
                {client.clientsDataController ?
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <th className='text-start'>Entité légale</th>
                                <td className='text-start'>{client.clientsDataController.cdcLegalEntityFlag ? 'Personne physique' : 'Personne morale'}</td>
                            </tr>
                            <tr>
                                <th className='text-start'>Nom</th>
                                <td className='text-start'>{client.clientsDataController.cdcName ? client.clientsDataController.cdcName : "-"}</td>
                            </tr>
                            <tr>
                                <th className='text-start'>Prénom</th>
                                <td className='text-start'>{client.clientsDataController.cdcFirstname ? client.clientsDataController.cdcFirstname : "-"}</td>
                            </tr>
                            <tr>
                                <th className='text-start'>SIRET</th>
                                <td className='text-start'>{client.clientsDataController.cdcRegistrationNumber ? client.clientsDataController.cdcRegistrationNumber : "-"}</td>
                            </tr>
                            <tr>
                                <th className='text-start'>Fonction</th>
                                <td className='text-start'>{client.clientsDataController.functionsCategories ? client.clientsDataController.functionsCategories.name : ""}</td>
                            </tr>
                            <tr>
                                <th className='text-start'>Informations</th>
                                <td className='text-start' >{client.clientsDataController.cdcInformations ? client.clientsDataController.cdcInformations : "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <p>non défini</p>
                }
            </div>
        </article>
    );
}

export default ClientDataController;
