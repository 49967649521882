import React, {useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import TextareaLabel from 'components/generics/TextareaLabel';
import { addClientComment } from 'services/API/Clients';
import ButtonsForm from 'components/generics/ButtonsForm';


const ClientComments = ({ client, isShowComments }) => {
  const history = useHistory();
  const [formData, setFormData] = useState(client);

  const handleOnSubmit = (event) => {
    if (event) event.preventDefault();


    addClientComment(formData)
    .then(() => {
        toast.success(`Client ${client.clName} mis à jour`)
        history.push(`/clients/all`);
    })
    .catch((err) => toast.error(err.response.data['message']));

  };


  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <section className="mt-10 mb-5">
        <form className="form-client" onSubmit={handleOnSubmit}>


          <TextareaLabel name="comments" 
            value={formData.comments? formData.comments : ""} 
            defaultStyle='secondary' 
            placeholder="Ecrivez un commentaire"
            label="" 
            disabled={isShowComments}
            onChange={(e) => {
              updateForm('comments', e.target.value);
            }} />

          {!isShowComments &&
            <ButtonsForm route={`/`} isSaveButton={true} />
          }
        </form >
      </section>
    </>
  );
}

export default ClientComments;
