import React from 'react';


import { jsPDF } from "jspdf";

import Poppins from 'assets/fonts/Poppins-Regular.ttf';
import PoppinsBold from 'assets/fonts/Poppins-Bold.ttf';


/**
 * Generate the PDF document with jsPDF library
 * @param allHtml 
 * @param fileName
 */
function PdfDocument(htmlData, fileName) {

	var doc = new jsPDF({
		putOnlyUsedFonts: true,
		orientation: 'portrait',
		format: 'a4',
		fontSize: 8,
		unit: 'px',
		hotfixes: ["px_scaling"]
	}
	);
	doc.addFont(Poppins, "Poppins", "normal");
	doc.addFont(PoppinsBold, "Poppins", "bold");
	doc.setFont("Poppins");

	doc.html(htmlData, {
		callback: function (doc) {
			// Delete the last blank page
			var pageCount = doc.internal.getNumberOfPages();
			doc.deletePage(pageCount);

			// Save the PDF file
			doc.save(fileName + '.pdf');
		},
		x: 0,
		y: 0
	});
}

export default PdfDocument;