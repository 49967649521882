import React from 'react';
import PropTypes from 'prop-types';

const Bell = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" 
      />
    </g>
  </svg>
);

Bell.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Bell.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Bell;