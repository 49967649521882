import Circle from 'components/icons/Circle';
import Client from 'components/icons/Client';
import Delete from 'components/icons/Delete';
import Prospect from 'components/icons/Prospect';
import Show from 'components/icons/Show';

import React from 'react';
import { NavLink } from 'react-router-dom';

const ClientTableRow = ({ data, handleRemoveClient }) => {
    console.log(data);
    return (
        <>
            {
                data && data.actions !== "D" &&
                <tr >
                    <td >{data.clStatus === true ? <i  ><Circle fill={'green'} color={'green'} /></i> : <i  ><Circle fill={'orange'} color={'orange'} /></i>}</td>

                    <td className='text-center'>{data.clClientCode ? `${data.clClientCode}` : '-'}</td>
                    <td className='text-start'>{data.clName ? `${data.clName}` : '-'}</td>
                    <td className='text-center'>{data.clProspectFlag === true ? (<p className='badge bg-success m-0'>CLIENT</p>) : (<p className='badge bg-warning m-0'>PROSPECT</p>)} </td>
                    <td></td>
                    <td className='text-center'>{data.clientsContacts  && data.clientsContacts.length > 0 ? `${data.clientsContacts.length}` : '-'} </td>
                    <td className='text-center'>{data.clientsDocs  && data.clientsDocs.length > 0 ? `${data.clientsDocs.length}` : '-'} </td>

                    <td >
                        <div className="actionContainer">
                            <NavLink to={{ pathname: `/client/show/${data.slug}`, state: { client: data } }} activeClassName="active">
                                <Show />
                            </NavLink>
                            <div className="div-delete-link" onClick={() => handleRemoveClient(data.id)}>
                                <Delete />
                            </div>
                        </div>
                    </td>
                </tr>
            }

        </>
    );
};

export default ClientTableRow;
