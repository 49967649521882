import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import $ from 'jquery';


import InputLabel from 'components/generics/InputLabel';
import BooleanCheckbox from 'components/generics/BooleanCheckbox';
import TextareaLabel from 'components/generics/TextareaLabel';
import SelectLabel from 'components/generics/SelectLabel';


import { getSiret } from 'services/SIRET/siret';
import { validEmail, validPhone, validOrgCode, validName, validZipcode, validCity, convertIdOrganismType } from 'utils';

import ButtonsForm from 'components/generics/ButtonsForm';
import Title from 'components/generics/Title';


const DEFAULT_FORM = {
  clOrgCode: '',
  typeOrganism: '',
  clRegistrationNumber: '',
  clName: '',
  clAddress: '',
  clZipcode: '',
  clCity: '',
  clEmail: '',
  clPhone: '',
  clWebsite: '',
  clStatus: false,
  clProspectFlag: false,
};

const ClientForm = (props) => {
  let client = {
    id: props.client ? props.client.id : '',
    slug: props.client ? props.client.slug : '',
    clOrgCode: props.client ? props.client.clOrgCode : '',
    typeOrganism: props.client ? props.client.typeOrganism : props.organismTypes[0],
    clClientCode: props.client ? props.client.clClientCode : '',
    clRegistrationNumber: props.client ? props.client.clRegistrationNumber : '',
    clName: props.client ? props.client.clName : '',
    clAddress: props.client ? props.client.clAddress : '',
    clZipcode: props.client ? props.client.clZipcode : '',
    clCity: props.client ? props.client.clCity : '',
    clEmail: props.client ? props.client.clEmail : '',
    clPhone: props.client ? props.client.clPhone : '',
    clStatus: props.client ? props.client.clStatus : false,
    clProspectFlag: props.client ? props.client.clProspectFlag : false
  };

  let isEditMode = props.client ? true : false;

  const [organismTypes, setOrganismTypes] = useState(null);
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [checkedProspect, setCheckedProspect] = useState(false);
  const [disabledProspect, setDisabledProspect] = useState(false);
  const [formData, setFormData] = useState(client || DEFAULT_FORM);


  useEffect(() => {
    if (organismTypes == null) {
      setOrganismTypes(props.organismTypes);
    }

    if (props.client) {
      setCheckedStatus(props.client.clStatus);
      setCheckedProspect(props.client.clProspectFlag);
      if (props.client.clProspectFlag === true) {
        setDisabledProspect(true);
      }

    }
  }, [props, organismTypes])

  function validate(formData) {
    let isErr = false;
    if (!validOrgCode.test(formData['clOrgCode'])) {
      isErr = true;
      toast.error('Code organ format erreur');
    }

    if (!isErr && !validName.test(formData['clName'])) {
      isErr = true;
      toast.error('Nom format erreur');
    }

    if (!isErr && formData['clAddress'].lenght < 0) {
      isErr = true;
      toast.error('Adresse format erreur');
    }

    if (!isErr && !validZipcode.test(formData['clZipcode'])) {
      isErr = true;
      toast.error('code postal format erreur');
    }

    if (!isErr && !validCity.test(formData['clCity'])) {
      isErr = true;
      toast.error('Ville format erreur');
    }

    if (!isErr && !validEmail.test(formData['clEmail'])) {
      isErr = true;
      toast.error('Email format erreur');
    }

    if (!isErr && !validPhone.test(formData['clPhone'])) {
      isErr = true;
      toast.error('Phone format erreur');
    }
    return isErr;

  }

  const handleOnSubmit = (event) => {
    if (event) event.preventDefault();

    if (!validate(formData)) {
      props.handleOnSubmit(formData);
    }
  };

  function handleSiret(name, value) {
    var nom = "";
    var numero_voie = "";
    var type_voie = "";
    var libelle_voie = "";
    var code_postal = "";
    var libelle_commune = "";

    getSiret(value)
    .then((data) => {
      let objet = data.data.etablissement;
      //donnee est la variable reçu du serveur avec les résultats


      if (typeof objet.unite_legale === 'object' && objet.unite_legale != null) {
        // console.log("dans objet unite_legale");
        nom = objet.unite_legale.denomination;
        numero_voie = objet.numero_voie;
        type_voie = objet.type_voie;
        libelle_voie = objet.libelle_voie;
        code_postal = objet.code_postal;
        libelle_commune = objet.libelle_commune;
      } else {
        // console.log("else not in objet unite_legale");
        nom = objet.denomination;
        numero_voie = objet.etablissement_siege.numero_voie;
        type_voie = objet.etablissement_siege.type_voie;
        libelle_voie = objet.etablissement_siege.libelle_voie;
        code_postal = objet.etablissement_siege.code_postal;
        libelle_commune = objet.etablissement_siege.libelle_commune;
      }

      document.querySelector('#clName').setAttribute("value", nom);
      formData['clName'] = nom;

      if (numero_voie == null) {
        numero_voie = "";
      }
      if (type_voie == null) {
        type_voie = "";
      }
      if (libelle_voie == null) {
        libelle_voie = "";
      }
      let address = numero_voie + " " + type_voie + " " + libelle_voie;
      // remove space before and after string
      $('#clAddress').val(address.trim());
      $('#clZipcode').val(code_postal);
      $('#clCity').val(libelle_commune);

       setFormData({ ...formData, 
        ['clRegistrationNumber']: value,
        ['clAddress']: address.trim(),
        ['clZipcode']: code_postal,
        ['clCity']: libelle_commune });
      

    })
    .catch(() => toast.error('Pas de SIRET trouvé'));
  }

  const handleInputChange = (name, value) => {
    if (name === 'clRegistrationNumber') {
      if (value.length === 14) {
        handleSiret(name, value);
      }
      setFormData({ ...formData, [name]: value });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });


  return (
    <React.Fragment>
      <div className="container main-form" >
        <Title
          className="div-header"
          text={isEditMode ? "Editer un client " : "Ajouter un client "}
          />

        <div className="div-form">
          <form className="form-client" onSubmit={handleOnSubmit}>
            <div className="row">
              <div className="col">
                {isEditMode &&
                  <InputLabel
                    name="clClientCode"
                    value={formData.clClientCode}
                    defaultStyle='secondary'
                    label="Code Client"
                    disabled={isEditMode} />
                }
              </div>

            </div>
            <div className="row">
              <div className="col">
                {isEditMode ?
                  <InputLabel
                    name="clOrgCode"
                    value={formData.clOrgCode}
                    defaultStyle='secondary'
                    label="Code Organisation"
                    disabled={isEditMode} />
                  :
                  <InputLabel
                    name="clOrgCode"
                    value={formData.clOrgCode.toUpperCase()}
                    defaultStyle='secondary'
                    label="Code Organisation"
                    onChange={(e) => { handleInputChange('clOrgCode', e); }} />
                }
              </div>

              <div className="col">
                {organismTypes &&
                  <SelectLabel
                    defaultStyle='secondary'
                    label="Type"
                    name='typeOrganism'
                    onChange={(e) => {
                      updateForm('typeOrganism', convertIdOrganismType(+e, organismTypes));
                    }}
                    options={organismTypes}
                    value={formData.typeOrganism.id}
                    itemLabel='name'
                    itemValue='id'
                  />
                }</div>

            </div>
            <div className="col"></div>


            {/* <section> */}

            <div className="row">
              <div className="col">
                {isEditMode ?
                  <InputLabel
                    name="clRegistrationNumber"
                    value={formData.clRegistrationNumber}
                    defaultStyle='secondary'
                    label="SIRET"
                    disabled={true} />
                  :
                  <InputLabel name="clRegistrationNumber"
                    value={formData.clRegistrationNumber}
                    defaultStyle='secondary'
                    label="SIRET"
                    disabled={false}
                    type="text"
                    onChange={(e) => { handleInputChange('clRegistrationNumber', e); }} />
                }</div>
              <div className="col">{isEditMode ?
                <InputLabel name="clName"
                  value={formData.clName}
                  defaultStyle='secondary'
                  label="Nom" disabled={true} />
                :
                <InputLabel name="clName"
                  value={formData.clName}
                  defaultStyle='secondary'
                  label="Nom"
                  disabled={false}
                  onChange={(e) => { handleInputChange('clName', e); }} />
              }</div>
            </div>

            <div className="row">
              <div className="col">
                <TextareaLabel name="clAddress"
                  value={formData.clAddress}
                  defaultStyle='secondary'
                  label="Adresse"
                  onChange={(e) => {
                    updateForm('clAddress', e.target.value);
                    // setFormData({ ...formData, ['clAddress']: e.target.value }); 
                  }}
                />
              </div>

            </div>

            <div className="row">
              <div className="col">
                <InputLabel name="clZipcode"
                  value={formData.clZipcode}
                  defaultStyle='secondary'
                  label="Code postal"
                  disabled={false}
                  onChange={(e) => { handleInputChange('clZipcode', e); }} />
              </div>
              <div className="col">
                <InputLabel name="clCity"
                  value={formData.clCity}
                  defaultStyle='secondary'
                  label="Ville"
                  disabled={false}
                  onChange={(e) => { handleInputChange('clCity', e); }} />
              </div>
            </div>

            <div className="row">
              <div className="col"> <InputLabel name="clEmail"
                value={formData.clEmail}
                defaultStyle='secondary'
                label="Email"
                disabled={false}
                onChange={(e) => { handleInputChange('clEmail', e); }} type="email" />
              </div>
              <div className="col">
                <InputLabel name="clPhone"
                  value={formData.clPhone}
                  defaultStyle='secondary'
                  label="Numéro de téléphone"
                  disabled={false}
                  onChange={(e) => { handleInputChange('clPhone', e); }} type="tel" />
              </div>
            </div>

            <div className="row d-inline">
              <div className="col checkbox-label">

                <BooleanCheckbox
                  label="Statut"
                  checked={checkedStatus}
                  onChange={() => {
                    setCheckedStatus(!checkedStatus)
                    updateForm('clStatus', !checkedStatus);
                  }}

                  value={formData.clStatus}
                  onlabel='Actif'
                  offlabel='Inactif'
                />
              </div>
              <div className="col checkbox-label">
                <BooleanCheckbox
                  label="Catégorie"
                  checked={checkedProspect}
                  onChange={() => {
                    setCheckedProspect(!checkedProspect)
                    updateForm('clProspectFlag', !checkedProspect);
                  }}
                  value={formData.clProspectFlag}
                  onlabel='Client'
                  offlabel='Prospect'
                  disabled={disabledProspect}
                />
              </div>
            </div>

            <ButtonsForm route={`/clients/all`}  isSaveButton={true} isBackButton={true} />
          </form>
        </div>

      </div>
    </React.Fragment >
  );
};

export default ClientForm;