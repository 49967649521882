import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/generics/Input';
import './inputLabel.style.scss';
import Eye from 'components/icons/Eye';
import EyeHidden from 'components/icons/EyeHidden';

import "./inputPassword.style.scss";

const InputPassword = ({
  defaultStyle,
  disabled,
  name,
  required,
  type,
  value,
  onChange,
  color,
  placeholder,
  label,
  onClick, 
  passwordShown
}) => {

  // 




  return (
    <label className={defaultStyle} htmlFor={name}>
      {label}
      <div className='pass-wrapper'>
      <Input
        onChange={onChange}
        value={value}
        defaultStyle={defaultStyle}
        disabled={disabled}
        name={name}
        required={required}
        type={passwordShown ? "text" : "password"}
        style={color}
        placeholder={placeholder}
        aria-label={name}

      />
      <span className="password__show" onClick={onClick}>{passwordShown ? <Eye /> : <EyeHidden /> }</span>
      </div>


    </label>
  );
}




InputPassword.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
};

InputPassword.defaultProps = {
  defaultStyle: '',
  disabled: false,
  name: '',
  required: false,
  type: 'text',
  color: '',
  placeholder: '',
};

export default InputPassword;
