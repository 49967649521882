export default function QuoteTitle({ quoteNumber, quoteDate }) {
    return (
      <>
        <div className="invoice-titel">
            <div className="row">
                <div className="invoice2 col-sm-6">
                    <h3>Devis n° <span>{quoteNumber}</span></h3>
                </div>
                <div className="date col-sm-6 text-end">
                    <h3>En date du <span>{quoteDate}</span></h3>
                </div>
            </div>
        </div>
      </>
    )
  }