import React from 'react';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';

import { updateContact } from 'services/API/Clients';

import ContactForm from './ContactForm';

export default function EditContact() {
  const history = useHistory();
  const location = useLocation();
  let contact = location.state.contact;
  let functionsCategories = location.state.functionsCategories;
  let clientId = location.state.clientId;

  const handleOnSubmit = (contact) => {
    contact["clientId"] = clientId;
    updateContact(contact)
      .then(() => {
        toast.success(`Contact ${contact.ccName} mis à jour`)
        history.push('/clients/all');
      })
      .catch((err) => toast.error(err.response.data['message']));
  };

  
  return (
    <React.Fragment>
      {contact  &&  functionsCategories && 
        <div>

        
        <h1>ffsfd</h1>
        <ContactForm isEditMode={true} contact={contact} functionsCategories={functionsCategories} handleOnSubmit={handleOnSubmit} /></div>
}
    </React.Fragment>

  )
}
