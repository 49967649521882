import React from 'react';
import IconButton from 'components/generics/IconButton';
import Edit from 'components/pages/Home/Edit';
// import Delete from 'components/icons/Delete';

const ProductsTable  = props => (
    <table>
      <thead>
        <tr>
          <th>Référence</th>
          <th>Désignation</th>
          <th>prix unitaire</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.products.length > 0 ? (
          props.products.map(item => (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td>{item.content}</td>
              <td>{item.priceUnit}</td>
              <td className="actionner">
                <IconButton onClick={() => props.editRow(item)} icon={<Edit  />} />
                {/* <IconButton  onClick={() => props.handleDeleteProducts(item.id)} icon={<Delete  />} /> */}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>Pas de produits référencés pour les devis</td>
          </tr>
        )}
      </tbody>
    </table>
  )
  

export default ProductsTable;

