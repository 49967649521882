import React, { useState } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';


const AddProductsForm = props => {
    const initialFormState = { id: null, title: '', content: '', priceUnit: 0.0, category: '' }
    const [products, setProducts] = useState(initialFormState)

    const handleInputChange = (name, value) => {
        setProducts({ ...products, [name]: value })
    }

    return (
        <form className="form-client"
            onSubmit={event => {
                event.preventDefault()
                if (!products.title || !products.content || !products.priceUnit) return

                props.handleAddProducts(products)
                setProducts(initialFormState)
            }}
        >
            <InputLabel
                name="title"
                type="text"
                value={products.title}
                defaultStyle='secondary'
                label="Référence"
                onChange={(e) => { handleInputChange('title', e); }} />
            <InputLabel
                name="content"
                type="text"
                value={products.content}
                defaultStyle='secondary'
                label="Designation"
                onChange={(e) => { handleInputChange('content', e); }} />
            <InputLabel
                name="priceUnit"
                type="number"
                value={products.priceUnit}
                defaultStyle='secondary'
                label="Prix unitaire"
                onChange={(e) => { handleInputChange('priceUnit', e); }} />

            <div className="div-button">
                <Button
                    type="submit"
                    defaultStyle="btn btn-primary"
                    text="Enregistrer"
                    textColor="primary"
                />
            </div>
        </form>
    )
}
export default AddProductsForm;

