import React from 'react';
import PropTypes from 'prop-types';

const Logout = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g>
      <path
        color={color}
        fill={fill}
        d="M2 12l5 4v-3h9v-2H7V8z M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051s2.051 3.08 2.051 4.95s-.729 3.628-2.051 4.95s-3.08 2.051-4.95 2.051s-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z"
      />
    </g>
  </svg>
);

Logout.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Logout.defaultProps = {
  color: '#000',
  fill: '#0e384d',
  width: 18.605,
  height: 18.605,
};

export default Logout;
