import React, {useEffect,useState}  from 'react';
import { Redirect, Switch, Route} from 'react-router-dom';

import useAuth from 'providers/Auth/useAuth';
import ClientsHome from 'components/pages/Clients/Home';

import 'assets/styles/index.scss';
import './clients.style.scss';

const ClientsPage = () => {
  const { user } = useAuth();
  // let clients = user.clients;
  const [clients, setClients] = useState(user.clients);
 
  useEffect(() => {
    setClients(user.clients);
  }, [user]);
  return (

    <div>
      <Switch>
        <Route exact path="/clients">
          <Redirect to="/clients/all" />
        </Route>
        <Route exact path="/clients/all">
          <ClientsHome clients={clients} /> 
        </Route>
      </Switch>
    </div>
  );
};

export default ClientsPage;
