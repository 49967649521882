import React from 'react';

import Checkbox from 'components/generics/Checkbox';
import InputLabel from 'components/generics/InputLabel';

const QuoteDiscount = ({totals, calculateTotal}) => {
    return (
        <div className="div-discount">
        <Checkbox
           checked={totals.isDiscount}
           onChange={(e) => {

              calculateTotal(!totals.isDiscount, 
              totals.isDiscountAmount, 
              totals.downPaymentAmount, 
              totals.downPaymentPercent);

             }}
           label="Remise"
           value='*'
         />
           {
              totals.isDiscount &&
              <div> 
              <nav>
                 <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-amount-tab" data-bs-toggle="tab" data-bs-target="#nav-amount" type="button" role="tab" aria-controls="nav-amount" aria-selected="true">€</button>
                    <button className="nav-link" id="nav-percent-tab" data-bs-toggle="tab" data-bs-target="#nav-percent" type="button" role="tab" aria-controls="nav-percent" aria-selected="false">%</button>    
                 </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                 <div className="tab-pane fade show active" id="nav-amount" role="tabpanel" aria-labelledby="nav-amount-tab">
                    <InputLabel  
                    type="number"
                       name="downPaymentAmount" 
                       value={ totals.downPaymentAmount } 

                       onChange={(e) => { 
                          calculateTotal(true, true, parseFloat(e), totals.downPaymentPercent);
                        }}
                       />
                 </div>
                 <div className="tab-pane fade" id="nav-percent" role="tabpanel" aria-labelledby="nav-percent-tab">
                    <InputLabel  
                    type="number"
                       name="downPaymentPercent" 
                       value={ totals.downPaymentPercent } 

                       onChange={(e) => { 
                          calculateTotal(true, false, totals.downPaymentAmount, parseFloat(e));
                        }}
                       />
                 </div>
              </div>
              </div>
        }
        </div>
    );
}

export default QuoteDiscount;
