import React from 'react';
import './title.style.scss';

import { NavLink } from 'react-router-dom';
import UserPlus from 'components/icons/UserPlus';
import Plus from 'components/icons/Plus';
import Invoice from 'components/icons/Invoice';
import Back from 'components/icons/Back';

const Title = ({ text, isAddButton=false, isPlusButton=false, isDoc=false, isBackButton=false, routeAdd, routePlus, routeDoc, routeBack="/" }) => {
    return (

        <div className="div-header d-flex justify-space-between pb-3">

            <h1
                className="div-header card-title text-center flex-2 " >
                {text}
            </h1>
            {isAddButton &&
                <NavLink to={routeAdd}
                    className="button-plus"
                >
                    <UserPlus />
                </NavLink>
            }

            {isPlusButton &&
                <NavLink to={routePlus}
                    className="logoplus px-3"
                >
                    <Plus />
                </NavLink>
            }

            {isDoc &&
                <NavLink to={routeDoc}
                    className="invoice"
                >
                    <Invoice />
                </NavLink>
            }
            {isBackButton &&
                <NavLink to={routeBack}
                    className="invoice"
                >
                    <Back />
                </NavLink>
            }

        </div>


    );
}

export default Title;