import React, { useState } from 'react';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel';
import ButtonsForm from 'components/generics/ButtonsForm';
import SelectLabel from 'components/generics/SelectLabel';
import Title from 'components/generics/Title';

import { validPhone, validEmail, validName, convertIdFunctionCategories} from 'utils';


const ContactForm = (props) => {
    let isEditMode = props.isEditMode;
    let functionsCat = props.functionsCategories ? props.functionsCategories : null;
    const [formData, setFormData] = useState(props.contact);

    function validate(formData) {
        let isErr = false;

        if (!validName.test(formData['ccName'])) {
            isErr = true;
            toast.error('Nom format erreur');
        }

        if (!isErr && !validEmail.test(formData['ccEmail'])) {
            isErr = true;
            toast.error('Email format erreur');
        }

        if (!isErr && !validPhone.test(formData['ccPhone'])) {
            isErr = true;
            toast.error('Phone format erreur');
        }
        return isErr;

    }

    const handleOnSubmit = (event) => {
        if (event) event.preventDefault();
        if (!validate(formData)) {
            props.handleOnSubmit(formData);
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

 
    return (
        <div className="container main-form" >
            <Title
                className="div-header"
                text={isEditMode ? "Editer un contact " : "Ajouter un contact "}
                />
            <div className="div-form">
            <form className="form-client" onSubmit={handleOnSubmit}>
                <InputLabel name="ccName" value={formData.ccName}  defaultStyle='secondary' label="Nom"
                    onChange={(e) => {handleInputChange('ccName', e);}}  />

                <InputLabel name="ccFirstname" value={formData.ccFirstname}  defaultStyle='secondary' label="Prénom" 
                    onChange={(e) => {handleInputChange('ccFirstname', e);}}  />

                <InputLabel name="ccPhone" value={formData.ccPhone}  defaultStyle='secondary' label="Numéro de téléphone"
                    onChange={(e) => {handleInputChange('ccPhone', e);}} type="tel" />

                <InputLabel name="ccEmail" value={formData.ccEmail}  defaultStyle='secondary' label="Email"
                    onChange={(e) => {handleInputChange('ccEmail', e);}} type="email" />

                <SelectLabel
                    defaultStyle='secondary width-middle'
                    label="Fonction"
                    name='functionsCategories'
                    onChange={(e) => {
                        handleInputChange('functionsCategories', convertIdFunctionCategories(+e, functionsCat));
                    }}
                    options={functionsCat}
                    value={formData.functionsCategories.id}
                    itemLabel='name'
                    itemValue='id' 
                />
                <ButtonsForm route={`/clients/all`} isSaveButton={true} isBackButton={true} />
            </form>

            </div>
        </div>
    );
};

export default ContactForm;