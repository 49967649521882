import React, { useState } from 'react';

import "./quoteproduct.style.scss";

import Input from 'components/generics/Input';

import QuoteTableRow from './QuoteTableRow';
import QuoteTotals from './QuoteTotals';
import QuoteDiscount from './QuoteDiscount';

import { calculateTTCAmount } from 'utils';
import Plus from 'components/icons/Plus';
import IconButton from 'components/generics/IconButton';
import TableHead from 'components/generics/Table/TableHead';

var tableRowIndex = 0;

const HEADER_TABLE = ['Référence', 'Désignation', 'JH', 'Prix unit.', 'Montant HT', 'Montant TTC', ''];

function QuoteProducts({isEditMode, quote, handleData, products, tva}) {
  
   const product = products[0];
   const DEFAULT_ROW = [{
      id: tableRowIndex, 
      title: product.title, 
      content:product.content, 
      quantity: 0, 
      priceUnit:product.priceUnit, 
      priceHT:0, 
      priceTTC:0 }];

   const DEFAULT_TOT = {
      subTotalHt: 0, 
      totalTtc: 0,
      isDiscount: false, 
      isDiscountAmount: true, 
      discount: 0,
      totalTva: 0, 
      downPaymentAmount: 200, 
      downPaymentPercent: 5 };
   var newQuoteProducts = [];
   const [quoteProducts, setQuoteProducts] = useState(isEditMode? editQuoteProducts(): DEFAULT_ROW);
   const [totals, setTotals] = useState(isEditMode? 
      {
      subTotalHt: quote.amountHT, 
      totalTtc: quote.amountTTC,
      isDiscount: quote.isDiscount, 
      isDiscountAmount: quote.isDiscountAmount, 
      discount: quote.discount,
      totalTva: quote.amountHt * (tva /100), 
      downPaymentAmount: quote.discount, 
      downPaymentPercent: 5 
   } :
   DEFAULT_TOT);

   function editQuoteProducts (){

      if (isEditMode){
         // tableRowIndex = quote.length;
         // setQuoteProducts(quote.quoteProducts);

         var newQuoteProducts = [];
         
         quote.quoteProducts.map(product => {
            newQuoteProducts[tableRowIndex] = {
               id: tableRowIndex, 
               title: product.title, 
               content:product.content, 
               quantity: product.quantity,
               priceUnit:product.priceUnit, 
               priceHT:product.priceHT,  
               priceTTC:product.priceTTC   }

            tableRowIndex = parseFloat(tableRowIndex) + 1;

         });
   
         return newQuoteProducts;
      }
   }

   // useEffect(() => {



   //       // setTotals({
   //       //    subTotalHt: quote.amountHt, 
   //       //    totalTtc: quote.amountTTC,
   //       //    isDiscount: quote.isDiscount, 
   //       //    isDiscountAmount: quote.isDiscountAmount, 
   //       //    discount: quote.discount,
   //       //    totalTva: quote.amountHt * (tva /100), 
   //       //    downPaymentAmount: quote.discount, 
   //       //    downPaymentPercent: 5 
   //       // });


   //    }
   //  }, []);

   // Receive data from quoteProducts 
   const handleChange = data => {
      quoteProducts[data.id] = data;
      calculateTotal(totals.isDiscount, 
         totals.isDiscountAmount, 
         totals.downPaymentAmount, 
         totals.downPaymentPercent);
   }

   const calculateTotal = (isDiscount, isDiscountAmount, downPaymentAmount, downPaymentPercent) => {

      let ht = calculateSubTotal();
      let ttc = calculateTotalBeforeDiscount();
      let totalTva = ht * (tva /100);
      let newTTC = ttc;

      let remise = 0;

      // Apply discount
      if (isDiscount){
         if (isDiscountAmount){
            remise = downPaymentAmount;
         }
         else{
            remise = ht * (downPaymentPercent / 100);
         }
         let newHt = ht - remise;
         newTTC = calculateTTCAmount(newHt, tva);
         totalTva = newHt * (tva /100);
      }
      setTotals({
         subTotalHt: ht, 
         totalTtc: newTTC,
         isDiscount: isDiscount, 
         isDiscountAmount: isDiscountAmount, 
         discount: remise,
         totalTva: totalTva, 
         downPaymentAmount: downPaymentAmount, 
         downPaymentPercent: downPaymentPercent});

         handleData(totals, quoteProducts);
   }

   function calculateSubTotal (){
      return quoteProducts.reduce((a,v) =>  a = a + parseFloat(v.priceHT) , 0 );
   }
   function calculateTotalBeforeDiscount (){
      return quoteProducts.reduce((b,v) =>  b = b + parseFloat(v.priceTTC) , 0 );
   }

   // Add New Table Row
   const addNewRow = () => {
      tableRowIndex = parseFloat(tableRowIndex) + 1;
      var updatedRows = quoteProducts ? [...quoteProducts] : [];
      updatedRows[tableRowIndex] = {
         id: tableRowIndex, 
         title: product.title, 
         content:product.content, 
         quantity: 0,
         priceUnit:product.priceUnit, 
         priceHT:0, 
         priceTTC:0 }

      setQuoteProducts(updatedRows)
   }
   
   // Remove Table row if rows are count is more than 1
   const deleteRow = (index) => {
      console.log(index);
      if(quoteProducts.length > 1){
         var updatedRows = [...quoteProducts]
         var indexToRemove = updatedRows.findIndex(x => x.id === index);
         if(indexToRemove > -1){
            updatedRows.splice(indexToRemove, 1)
            setQuoteProducts(updatedRows);
         }
      }
   }

  return (
    <div className="card">
      <div className="card-header searchbar_align">
         <IconButton 
            backgroundColor="#3b7bac" 
            textColor="#ffff"
            type="button"
            text=""
            onClick={addNewRow} icon={<Plus width={30} height={30} color="#3b7bac" fill="#ffff" />} />

      </div>
      <div className="card-body table-responsive table-responsive-md">
  
         <table className="table table-hover text-center order-list" id="customers" data-toggle="table" data-search="true" data-pagination="true">
            <TableHead items={HEADER_TABLE} />

            <tbody>
               { quoteProducts && quoteProducts.map((row, index) => {
                  if(row)
                  return(
                     <QuoteTableRow 
                        /*key={index} */
                        row={row} 
                        handleDataChange={handleChange} 
                        deleteRow={deleteRow} 
                        products={products} 
                        tva={tva} 
                     />
                  )
                  })
               }
            </tbody>
         </table>
      </div>

       { quoteProducts && <Input type="hidden" name="quoteProductsLenght" value={quoteProducts.length} />}



      <div className="div-footer">
         <QuoteDiscount totals={totals} calculateTotal={calculateTotal} />
         <QuoteTotals totals={totals} />
        { totals && 
        <>
         <Input type="hidden" name="amountHT" value={totals.subTotalHt.toFixed(2)} />
         <Input type="hidden" name="amountTTC" value={totals.totalTtc.toFixed(2)} />
         <Input type="hidden" name="isDiscount" value={totals.isDiscount} />
         <Input type="hidden" name="discount" value={totals.discount.toFixed(2)} />
         </>
        }
      </div>
    </div>
  );
}

export default QuoteProducts;