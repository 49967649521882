import React, { useState } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';

const AddQuoteStatusForm = props => {
	const initialFormState = { id: null, name: '', description: '' }
	const [ quoteStatus, setQuoteStatus ] = useState(initialFormState)

	const handleInputChange = (name, value) => {
		setQuoteStatus({ ...quoteStatus, [name]: value })
	}

	return (
		<form className="form-client"
			onSubmit={event => {
				event.preventDefault()
				if (!quoteStatus.name || !quoteStatus.description) return

				props.handleAddQuoteStatus(quoteStatus)
				setQuoteStatus(initialFormState)
			}}
		>
			<InputLabel
				name="name"
				type="text"
				value={quoteStatus.name}
				defaultStyle='secondary'
				label="Nom"
				onChange={(e) => { handleInputChange('name', e); }}/>
			<InputLabel
				name="description"
				type="text"
				value={quoteStatus.description}
				defaultStyle='secondary'
				label="Description"
				onChange={(e) => { handleInputChange('description', e); }}/>
			
			
			<div className="div-button">
				<Button
					type="submit"
					defaultStyle="btn btn-primary"
					text="Enregistrer"
					textColor="primary"
				/>
			</div>
		</form>
	)
}
export default AddQuoteStatusForm;
