import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { forgotPassword } from 'services/API/User';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';

const DEFAULT_FORM = { email: '' };

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    forgotPassword(formData)
      .then(() => {
        toast.success('Un mail vous a été envoyé');
        setLoading(false);
        setFormData(DEFAULT_FORM);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Impossible de re-initialiser votre mot de passe')
      })
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel
          name="email"
          value={formData.email}
          onChange={(e) => {
            updateForm('email', e);
          }}
          placeholder="E-mail"
          label="Entrez votre adresse mail"
          defaultStyle='login'
          disabled={loading}
        />
        <div className="d-flex">
        <Button defaultStyle='d-block' type="submit" text="Envoyer l'e-mail" disabled={loading} />
        </div>
      </form>
    </>
  );
};

export default LoginForm;
