import React from 'react';
import TableHead from 'components/generics/Table/TableHead';
import Edit from 'components/icons/Edit';
import UserPlus from 'components/icons/UserPlus';
import Delete from 'components/icons/Delete';

import { NavLink } from 'react-router-dom';

const CONTACT_HEADER = ['Nom', 'Prénom', 'Fonction', ''];

const ClientContacts = ({ client, functionsCategories, handleRemoveContact }) => {
    return (
        <article className="card" >
            <div className="card-body">
                <span className="d-flex justify-space-between">
                    <h4 className="card-title text-center pb-3 flex-2">Contacts</h4>
                    <NavLink to={{
                        pathname: `/client/${client.slug}/contacts/add`,
                        state: {
                            client: client,
                            functionsCategories: functionsCategories
                        }
                    }} activeClassName="active"><UserPlus /></NavLink>
                </span>

                <div className='table-responsive table-responsive-md table-responsive-sm'>
                    <table className='table m-0'>
                        <TableHead items={CONTACT_HEADER} />
                        <tbody>
                            {client.clientsContacts?.map(contact => (
                                <tr>
                                    <td>{contact.ccName ? `${contact.ccName}` : '-'}</td>
                                    <td>{contact.ccFirstname ? `${contact.ccFirstname}` : '-'}</td>
                                    <td>{contact.functionsCategories ? `${contact.functionsCategories.name}` : '-'}</td>
                                    <td>
                                        <div className="actionContainer">
                                            <NavLink to={{
                                                pathname: `/client/${client.slug}/contact/edit/${contact.slug}`,
                                                state: { clientId: client.id, contact: contact, functionsCategories: functionsCategories }
                                            }}
                                                activeClassName="active" ><Edit /></NavLink>
                                            <div className="div-delete-link" onClick={() => handleRemoveContact(contact.id)} > <Delete />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </article>
    );
}

export default ClientContacts;
