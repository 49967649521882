import React from 'react';

import logo from 'assets/images/logo/logo_couleur.jpg';
import './ResetPassword.style.scss';

import ResetForm from 'components/pages/ResetPassword/form';

const ResetPassword = () => (
  <div className='reset'>
    <div className='logoContainer'>
      <img className='logo m-auto d-block' src={logo} alt='logo solutions 909' />
      <h2 className='text-center title'>Nouveau mot de passe</h2>
    </div>
    <div className='container'>
      <ResetForm />
    </div>
  </div>
)

export default ResetPassword;
