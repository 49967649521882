import React from 'react';
import { Switch, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import LoginPage from 'components/pages/Login';
import ForgoPasswordPage from 'components/pages/ForgotPassword';
import ResetPasswordPage from 'components/pages/ResetPassword';

import RegistrationPage from 'components/pages/Registration';
import VerifyEmailPage from 'components/pages/VerifEmail';
import ComfirmCredentialsPage from 'components/pages/User/ConfirmCredentials';

// Page d'accueil
import HomePage from 'components/pages/Home';

// Clients
import ClientsPage from 'components/pages/Clients';

import AddClient from 'components/pages/Clients/Client/AddClient';
import EditClient from 'components/pages/Clients/Client/EditClient';
import ShowClient from 'components/pages/Clients/Client/ShowClient';

import ClientsDataControllerPage from 'components/pages/Clients/DataController';
import AddDataController from 'components/pages/Clients/DataController/AddDataController';
import EditDataController from 'components/pages/Clients/DataController/EditDataController';

// import ClientsContactPage from 'components/Clients/Contacts';
import AddContact from 'components/pages/Clients/Contacts/AddContact';
import EditContact from 'components/pages/Clients/Contacts/EditContact';

import ClientsDocsPage from 'components/pages/Clients/Docs';

import ClientsManagerPage from 'components/pages/Clients/Manager';

// Support 
import SupportPage from 'components/pages/Support';

// Users
import UsersPage from 'components/pages/User';
import EditUser from 'components/pages/User/EditUser';
import ShowUser from 'components/pages/User/ShowUser';


//User Profile
import ProfilePage from 'components/pages/User/Profil';

// Devis
import QuotesPage from 'components/pages/Quotes';
import EditQuote from 'components/pages/Quotes/EditQuote';
import AddQuote from 'components/pages/Quotes/AddQuote';
// import ShowQuote from 'components/Quotes/Show';
import ShowQuote from 'components/pages/Quotes/Show';

// Logging
import LogPage from 'components/pages/Log';

// Configuration
import SettingsPage from 'components/pages/Settings';

import GeneralPage from 'components/pages/Settings/General';
import QuoteStatusPage from 'components/pages/Settings/General/QuoteStatus';
import ProductsPage from 'components/pages/Settings/General/Products';
import FilesCatPage from 'components/pages/Settings/General/FilesCat';
import FunctionsCategoriesPage from 'components/pages/Settings/General/FunctionsCategories';
import TypeOrganismPage from 'components/pages/Settings/General/TypeOrganism';
// import AddQuoteStatus from 'components/Settings/General/QuoteStatus/AddQuoteStatusForm';
import System from 'components/pages/Settings/System';

import ProtectedRoute from 'providers/Auth/ProtectedRoute';

import PageNotFound from 'components/pages/Error/404-page';



const Routes = () => {

  return (
    <Switch >
      <Route path="/login"><LoginPage /></Route>
      <Route path="/forgotPassword"><ForgoPasswordPage /></Route>
      <Route path="/newpassword"><ResetPasswordPage /> </Route>
      <Route path="/verifyEmail"><VerifyEmailPage /> </Route>
      <Route path="/confirm"><ComfirmCredentialsPage /> </Route>

      {/* dashboard */}
      <Route path="/" exact={true}><ProtectedRoute><HomePage /></ProtectedRoute></Route>

      {/* Clients */}
      <Route path="/clients"><ProtectedRoute><ClientsPage /></ProtectedRoute></Route>

      {/* Client coordonnées */}
      <Route path="/client/add"><ProtectedRoute><AddClient /></ProtectedRoute></Route>
      <Route path="/client/edit/:slug"><ProtectedRoute><EditClient /></ProtectedRoute></Route>
      <Route path="/client/show/:slug"><ProtectedRoute><ShowClient /></ProtectedRoute></Route>

      {/* Client Représentant Légal */}
      <Route exact path="/client/:slug/dc"><ProtectedRoute><ClientsDataControllerPage /></ProtectedRoute></Route>
      <Route exact path="/client/:slug/dc/add"><ProtectedRoute><AddDataController /></ProtectedRoute></Route>
      <Route exact path="/client/:slug/dc/edit"><ProtectedRoute><EditDataController /></ProtectedRoute></Route>

      {/* Client contacts */}
      {/* <Route exact path="/client/:slug/contacts"><ProtectedRoute><ClientsContactPage /></ProtectedRoute></Route> */}
      <Route exact path="/client/:slug/contacts/add"><ProtectedRoute><AddContact /></ProtectedRoute></Route>
      <Route exact path="/client/:slug/contact/edit/:slugContact"><ProtectedRoute><EditContact /></ProtectedRoute></Route>


      {/* Client documents */}
      <Route path="/client/:slug/docs"><ProtectedRoute><ClientsDocsPage /></ProtectedRoute></Route>

      {/* Client manager : divers */}
      {/* <Route path="/client/:slug/divers"><ProtectedRoute><EditDiversClient /></ProtectedRoute></Route> */}

      {/* Client manager : gestionnaire */}
      <Route path="/client/:slug/manager"><ProtectedRoute><ClientsManagerPage /></ProtectedRoute></Route>

      {/* Support */}
      <Route exact path="/support"><ProtectedRoute><SupportPage /></ProtectedRoute></Route>

      {/* Devis */}
      <Route exact path="/devis"><ProtectedRoute><QuotesPage /></ProtectedRoute></Route>
      <Route path="/devis/add"><ProtectedRoute><AddQuote /></ProtectedRoute></Route>
      <Route path="/devis/show/:slug"><ProtectedRoute><ShowQuote /></ProtectedRoute></Route>
      <Route path="/devis/edit/:slug"><ProtectedRoute><EditQuote /></ProtectedRoute></Route>
      {/* <Route exact path="/devis"><QuotesPage /></Route> */}
      {/* <Route path="/devis/add"><AddQuote /></Route> */}
      {/* <Route path="/devis/edit/:slug"><EditQuote /></Route> */}
      {/* <Route path="/devis/add"><ProtectedRoute><AddQuote /></ProtectedRoute></Route> */}

      {/* User */}
      <Route exact path="/users"><ProtectedRoute><UsersPage /></ProtectedRoute></Route>
      <Route path="/registration"><ProtectedRoute><RegistrationPage /></ProtectedRoute></Route>
      <Route path="/user/:slug/edit"><ProtectedRoute><EditUser /></ProtectedRoute></Route>
      <Route path="/user/:slug/show"><ProtectedRoute><ShowUser /></ProtectedRoute></Route>

      {/* User Profil */}
      <Route path="/user/:slug/profil"><ProtectedRoute><ProfilePage /></ProtectedRoute></Route>

      {/* <Route path="/user/:slug/profile/edit"><ProtectedRoute><EditProfil /></ProtectedRoute></Route> */}


      {/* Log */}
      <Route exact path="/log"><ProtectedRoute><LogPage /></ProtectedRoute></Route>

      {/* Configuration */}
      <Route exact path="/configuration"><ProtectedRoute><SettingsPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale"><ProtectedRoute><GeneralPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/systeme"><ProtectedRoute><System /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale/quote-status"><ProtectedRoute><QuoteStatusPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale/products"><ProtectedRoute><ProductsPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale/files-cat"><ProtectedRoute><FilesCatPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale/functions-cat"><ProtectedRoute><FunctionsCategoriesPage /></ProtectedRoute></Route>
      <Route exact path="/configuration/generale/type-org"><ProtectedRoute><TypeOrganismPage /></ProtectedRoute></Route>
      {/* <Route exact path="/configuration/generale/quote-status/add"><ProtectedRoute><AddQuoteStatus /></ProtectedRoute></Route> */}


      {/* The last route */}
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
