import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import Title from 'components/generics/Title';
import Table from 'components/generics/Table/Table';
import useAuth from 'providers/Auth/useAuth';

import QuotesTableRow from './QuotesTableRow';
import { getAllQuotes, getAllQuoteStatus } from 'services/API/Quotes';


const HEADER_TABLE = ['Numéro de devis', 'Statut', 'Date de création', 'Date de modification', 'Date d\'envoi', 'Total TTC', ''];

const QuotesPage = () => {
  const { user } = useAuth();
  const [quotes, setQuotes] = useState(null);
  const [quoteStatus, setQuoteStatus] = useState(null);

  useEffect(() => {
    const getQuoteStatus = async () => {
      await getAllQuoteStatus()
        .then((g) => {
          setQuoteStatus(g.data.data);
        })
        .catch(() => toast.error('Impossible de charger les status des devis'));
    }
    getQuoteStatus();
  }, []);

  useEffect(() => {
    if (user) {
      getAllQuotes()
        .then((resp) => {
          setQuotes(resp.data.data);
        })
        .catch((err) => toast.error(err.response.data['message']));
    }
  }, [user]);

  const handleRemoveQuotes = (id) => {
    // setClients(clients.filter((client) => client.id !== id));

    console.log("handleRemoveQuotes");
  };

  return (
    <>
      <Title
        className=""
        text="Gestion des devis"
        isPlusButton={true}
        isDoc={true}
        routeDoc="/devis/add"
        routePlus="/devis/add"
      />

      <div className="div-list table-responsive table-responsive-md">
        {!_.isEmpty(quotes) ? (
          <Table header={HEADER_TABLE} data={quotes} RowElement={QuotesTableRow} handleRemoveQuote={handleRemoveQuotes} />
        ) : (
          <p className="message">Aucun devis disponible. Veuillez ajouter des devis.</p>
        )}
      </div>

    </>
  );
};

export default QuotesPage;
