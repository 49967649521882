import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import FilesCatTable from './FilesCatTable';
import AddFilesCatForm from './AddFilesCatForm';
import EditFilesCatForm from './EditFilesCatForm';
import { getAllFilesCategories, addFilesCat, updateFilesCat } from 'services/API/Clients';

import 'assets/styles/index.scss';

import './filescat.style.scss';

const FilesCatPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filesCat, setFilesCat] = useState(null);
  const initialFormState = { id: null, type: '', description: '' }

  // Setting state
  const [currentFilesCat, setCurrentFilesCat] = useState(initialFormState)
  const [editing, setEditing] = useState(false)

  const getFilesCat = async () => {
    await getAllFilesCategories()
      .then((g) => {
        setFilesCat(g.data.data);
        console.log(g.data.data);
        setIsLoading(true);
      })
      .catch(() => toast.error('Impossible de charger les catégories de fichiers'));
  }

  useEffect(() => {
    getFilesCat();
  }, []);

  // CRUD operations
  const handleAddFilesCat = obj => {
    addFilesCat(obj)
      .then(() => {
        getFilesCat();
        toast.success(`Catégorie de fichier ${obj.type} créé`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleUpdateFilesCat = (id, obj) => {

    updateFilesCat(obj)
      .then(() => {
        getFilesCat();
        setEditing(false)
        toast.success(`Catégorie de fichier  ${obj.type} mis à jour`);
      })
      .catch((err) => toast.error(err.response.data['message']));
  }

  const handleDeleteFilesCat = id => {
    setEditing(false)

    setFilesCat(filesCat.filter(obj => obj.id !== id))
  }
  const editRow = obj => {
    setEditing(true)

    setCurrentFilesCat({ id: obj.id, type: obj.type, description: obj.description })
  }
  return (
    <React.Fragment>
      {isLoading &&
        <div className="container">
          <div className="div-header pb-4">
            <h1 className="pb-4">Gestion des catégories de fichiers</h1>
          </div>
          <div className="div-quotestatus">

            <div >
              {!_.isEmpty(filesCat) ? (
                <FilesCatTable filesCat={filesCat} editRow={editRow} handleDeleteFilesCat={handleDeleteFilesCat} />
              ) : (
                <p className="message">Aucun statut disponible. Veuillez ajouter des statuts pour les devis.</p>
              )}
            </div>

            <div className="main-form">
              {editing ? (
                <Fragment>
                  <h2>Editer une catégorie de fichier </h2>
                  <EditFilesCatForm
                    editing={editing}
                    setEditing={setEditing}
                    currentFilesCat={currentFilesCat}
                    handleUpdateFilesCat={handleUpdateFilesCat}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <h2>Ajouter une catégorie de fichier</h2>
                  <AddFilesCatForm handleAddFilesCat={handleAddFilesCat} />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default FilesCatPage;
