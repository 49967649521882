import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import QuoteForm from './Quote/QuoteForm';
import { updateQuote } from 'services/API/Quotes';


export default function EditQuote() {
    const history = useHistory();
    const location = useLocation();
    let quote = location.state.quote ? location.state.quote : null;

    const handleOnSubmit = (quote) => {
        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
        updateQuote(quote, config)
            .then(() => {
                toast.success(`Devis ${quote.quoteNumber} mis à jour`)
                history.push('/devis');
            })
            .catch((err) => toast.error(err.response.data['message']));
    };


    return (
        <>

            <QuoteForm quote={quote} handleOnSubmit={handleOnSubmit} />
        </>
    )
}
