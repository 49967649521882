import React from 'react';

const QuoteTotals = ({ totals }) => {
    return (
        <div className="div-total">
            {totals &&
                <table className="quote-table-left-header self-end mb0 col col-6">
                    <tbody>
                        <tr>
                            <td className="text-end" >TOTAL HT</td>
                            <td >{totals.subTotalHt.toFixed(2)} €</td>
                        </tr>
                        {
                            totals.isDiscount ?
                                <>
                                    <tr className="bold">
                                        <td className="text-end">Remise globale HT</td>
                                        <td >{totals.discount.toFixed(2)} €</td>
                                    </tr>

                                    <tr className="bold">
                                        <td className=" text-end">TOTAL TTC</td>
                                        <td >{totals.totalTtc.toFixed(2)} €</td>
                                    </tr>
                                </>
                                :
                                <tr className="bold">
                                    <td className="text-end">TOTAL TTC</td>
                                    <td >{totals.totalTtc.toFixed(2)} €</td>
                                </tr>
                        }
                        <tr>
                            <td className="text-end">TOTAL TVA (20,00 %)</td>
                            <td >{totals.totalTva.toFixed(2)} €</td>
                        </tr>
                        <tr className="bold">
                            <td className="text-end">TOTAL TTC</td>
                            <td className="total-ttc">{totals.totalTtc.toFixed(2)} €</td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default QuoteTotals;
