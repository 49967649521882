import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { formatDateTime } from 'utils';
import { register } from 'services/API/User';
import {useHistory } from 'react-router-dom';

import InputLabel from 'components/generics/InputLabel';
import ButtonsForm from 'components/generics/ButtonsForm';
import Title from 'components/generics/Title';

const DEFAULT_FORM = {
  usName: '',
  usFirstname: '',
  email: '',
  usGroup: '',
  usStartedAt: formatDateTime(new Date()),
  usEndedAt: formatDateTime(new Date()),

};

const RegistrationForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const history = useHistory();


  const handleOnSubmit = (event) => {
    if (event) event.preventDefault();
    register(formData)
      .then(() => {
        toast.success(`Membre ${formData.usName} créé`)
        history.push('/');
      })
      .catch((err) => toast.error(err.response.data['message']));
  };

  function handleInputChange(name, value) {

    setFormData({ ...formData, [name]: value });
  }

  return (
    <React.Fragment>
      <Title
            className=""
            text="Nouveau collaborateur" 
            />

            
      <div className="container main-form" >
        <div className="div-form">
          <form className="form-client" onSubmit={handleOnSubmit}>
            <div className="row">
              {/* <div className="col"> */}
                {/* <Label className="secondary">Nom</Label> */}
                <InputLabel className="input-control"
                  label="Nom"
                  type="text"
                  name="usName"
                  value={formData.usName}
                  // placeholder="Saisissez le nom"
                  onChange={(e) => { handleInputChange('usName', e); }} />
              {/* </div> */}
            </div>

           <div className="row">
               {/* <div className="col"> */}
                {/* <Label className="secondary">Prénom</Label> */}
                <InputLabel className="input-control"
                label="Prénom"
                  type="text"
                  name="usFirstname"
                  value={formData.usFirstname}
                  // placeholder="Saisissez le prénom"
                  onChange={(e) => { handleInputChange('usFirstname', e); }} />
              {/* </div> */}
            </div>

            <div className="row">
              {/* <div className="col"> */}
                {/* <Label className="secondary">Email</Label> */}
                <InputLabel 
                  label="Email"
                  className="input-control"
                  type="email"
                  name="email"
                  value={formData.email}
                  // placeholder="Saisissez l'email"
                  onChange={(e) => { handleInputChange('email', e); }} />
              {/* </div> */}
            </div>


            <div className="row">
              {/* <div className="col"> */}
                {/* <Label className="secondary">Groupe</Label> */}
                <InputLabel className="input-control"
                  label="Groupe"
                  type="text"
                  name="usGroup"
                  value={formData.usGroup}
                  // placeholder="Saisissez le groupe"
                  onChange={(e) => { handleInputChange('usGroup', e); }} />
              {/* </div> */}
            </div>

                {/* <DateTimePicker
              name = "usStartedAt"
              onChange = { handleInputChange}
              value = {formData.usStartedAt}
            /> */}


            <div className="row">
              {/* <div className="col"> */}
                {/* <Label className="secondary">Date de début</Label> */}
                <InputLabel className="input-control"
                  label="Date de début"
                  type="datetime"
                  name="usStartedAt"
                  value={formData.usStartedAt}
                  // placeholder="Date de début"
                  onChange={(e) => { handleInputChange('usStartedAt', e); }} />
              {/* </div> */}
            </div>

            <div className="row">
              {/* <div className="col"> */}
                {/* <Label className="secondary"> Date de fin</Label> */}
                <InputLabel className="input-control"
                label="Date de fin"
                  type="datetime"
                  name="usEndedAt"
                  value={formData.usEndedAt}
                  placeholder="Date de fin"
                  onChange={(e) => { handleInputChange('usEndedAt', e); }} />
              {/* </div> */}
            </div>

              <ButtonsForm route={`/users`} isSaveButton={true} isBackButton={true}/>
          

          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegistrationForm;
