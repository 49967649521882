import React, { useState } from 'react';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';

const AddTypeOrganismForm = props => {
	const initialFormState = { id: null, name: '' }
	const [ typeOrganism, setTypeOrganism ] = useState(initialFormState)

	const handleInputChange = (name, value) => {
		setTypeOrganism({ ...typeOrganism, [name]: value })
	}

	return (
		<form className="form-client"
			onSubmit={event => {
				event.preventDefault()
				if (!typeOrganism.name) return

				props.handleAddTypeOrganism(typeOrganism)
				setTypeOrganism(initialFormState)
			}}
		>
			<InputLabel
				name="name"
				type="text"
				value={typeOrganism.name}
				defaultStyle='secondary'
				label="Type d'organisme"
				onChange={(e) => { handleInputChange('name', e); }}/>
			
			
			<div className="div-button">
				<Button
					type="submit"
					defaultStyle="btn btn-primary"
					text="Enregistrer"
					textColor="primary"
				/>
			</div>
		</form>
	)
}
export default AddTypeOrganismForm;
