import React from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import DocumentPlus from 'components/icons/DocumentPlus';
import TableHead from 'components/generics/Table/TableHead';
import { formatDateTime } from 'utils';
import FilePdf from 'components/icons/FilePdf';
// import Button from 'components/generics/Button';
// import axios from 'axios';
import { showClientDocument } from 'services/API/Clients';
import IconButton from 'components/generics/IconButton';

import './clientdocument.style.scss';

// const path = require('path');

const DOCUMENTS_HEADER = ['Nom', 'Catégorie',  'Date', ''];

const ClientDocuments = ({ client }) => {
    // const [PDFFile, setPDFFile] = useState(null);




    const handleShowPDF = (doc) => {
        let nom = "file:///C:/Users/godar/AppData/Local/Temp/son_i_nb.pdf";
        window.open(nom, "_blank");
        // showClientDocument(doc.id).then((resp) => {
        //     let pathDoc = resp.data.data;
        //     console.log(pathDoc);
        //     if (pathDoc !== ""){
        //         console.log("file:///" + pathDoc);
        //         let uri = "file:///" + pathDoc;
        //         window.open(pathDoc, "_blank");
        //     }

        // }).catch((err) => {
        //     toast.error(err.response.data['message'])
        // });
    };

    return (
        <article className="card card-doc" >
            <div className="card-body ">
                <span className="d-flex justify-space-between">
                    <h4 className="card-title text-center pb-3 flex-2">Documents</h4>
                    <NavLink to={{ pathname: `/client/${client.slug}/docs`, state: { client: client } }} activeClassName="active"  >
                        <DocumentPlus />
                    </NavLink>
                </span>
                <div className='table-responsive table-responsive-md table-responsive-sm'>
                <table className='table m-0'>
                    <TableHead items={DOCUMENTS_HEADER} />
                    <tbody>
                        {client.clientsDocs?.map(doc => (
                            <tr>
                                <td>{doc.cdFileName ? `${doc.cdFileName}` : '-'}</td>
                                {/* <td>{doc.cdExtension ? `${doc.cdExtension}` : '-'}</td> */}
                                <td>{doc.filesCat.type ? `${doc.filesCat.type}` : '-'}</td>
                                {/* <td>{doc.cdSize > 0 ? `${doc.cdSize}` : '-'}</td> */}
                                <td>{doc.cdCreatedAt ? `${formatDateTime(doc.cdCreatedAt.date)}` : '-'}</td>
                                <td>
                                    <IconButton
                                        onClick={() => handleShowPDF(doc)} icon={<FilePdf width={25.0} height={25.0} />} />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                </div>
                
            </div>
        </article>
    );
}

export default ClientDocuments;
