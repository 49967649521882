import React from 'react';
import IconButton from 'components/generics/IconButton';
import Edit from 'components/pages/Home/Edit';
// import Delete from 'components/icons/Delete';

const FilesCatTable  = props => (
    <table>
      <thead>
        <tr>
          <th>Type de fichier</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.filesCat.length > 0 ? (
          props.filesCat.map(item => (
            <tr key={item.id}>
              <td>{item.type}</td>
              <td>{item.description}</td>
              <td className="actionner">
                <IconButton onClick={() => props.editRow(item)} icon={<Edit  />} />
                {/* <IconButton  onClick={() => props.handleDeleteFilesCat(item.id)} icon={<Delete  />} /> */}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3}>Pas de catégories de fichier</td>
          </tr>
        )}
      </tbody>
    </table>
  )
  

export default FilesCatTable;
