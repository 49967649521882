import React from 'react';
// import { useHistory } from 'react-router-dom';

import ActionLink from 'components/generics/ActionLink';
import Show from 'components/icons/Show';
import Edit from 'components/icons/Edit';
import Delete from 'components/icons/Delete';

import './userTableRow.style.scss';

const UserTableRow = ({ data, handleRemoveUser }) => {
    //const history = useHistory();

    return (
        <>
            {
                data &&
                <tr className="table-style">
                    <td className="Name">{data.usName ? `${data.usName}` : '-'}</td>
                    <td className="Firstname">{data.usFirstname ? `${data.usFirstName}` : '-'}</td>
                    <td>{data.email ? `${data.email}` : '-'}</td>
                    <td>{data.usPhone ? `${data.usPhone}` : '-'}</td>
                    <td >
                        <div className="actionContainer">
                            <ActionLink to={{
                                pathname:`/user/${data.slug}/show`, 
                                state: {user : data}}} 
                                activeClassName="active" 
                                content={<Show/>} />
                                
                            <ActionLink to={{
                                pathname:`/user/${data.slug}/edit`, 
                                state: {user : data}}} 
                                activeClassName="active" 
                                content={<Edit/>} />
                                
                            <div className="div-delete-link" onClick={() => handleRemoveUser(data.id)} > <Delete/> </div>
                        </div>
                    </td>
                </tr>
            }

        </>
    );
};

export default UserTableRow;