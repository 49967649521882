import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import DataControllerForm from './DataControllerForm';

import { addDataController } from 'services/API/Clients';

const AddDataController = ({clientId,  functionsCat }) => {
  const history = useHistory();
    let dataController = {
            cdcLegalEntityFlag:  false,
            cdcRegistrationNumber: '',
            cdcName: '',
            cdcFirstname:  '',
            functionsCategories: functionsCat[0],
            cdcInformations:  ''
        };
  const handleOnSubmit = (dataController) => {
        dataController["clientId"] = clientId;
        addDataController(dataController)
          .then(() => {
              toast.success(`Représentant légal ${dataController.cdcName} créé`)
              history.push('/clients/all');
          })
          .catch((err) => toast.error(err.response.data['message']));
  };

  return (
    <React.Fragment>
      {functionsCat && 
        <DataControllerForm 
          functionsCat={functionsCat} 
          formData={dataController} 
          isEditMode={false} 
          handleOnSubmit={handleOnSubmit} />
      }
      
    </React.Fragment>
  );
};

export default AddDataController;
