import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { validStr, validEmail, validPhone, validDate, validCity, validZipcode } from 'utils';
import InputLabel from 'components/generics/InputLabel';
import ButtonsForm from 'components/generics/ButtonsForm';

const ProfilForm = ({ user, isShowProfil, handleOnSubmit }) => {
    const [formData, setFormData] = useState(user);
    
    const handleOnSubmitProfil = (event) => {
        if (event) event.preventDefault();

        if (!validate(formData)) {
            console.log(formData);
            handleOnSubmit(formData);
        }
    }


    function validate(formData) {
        
        let isErr = false;

        if (!validStr.test(formData['usName'])) {
            isErr = true;
            toast.error('Le nom n\'est pas renseigné');
        }
        if (!validStr.test(formData['usFirstname'])) {
            isErr = true;
            toast.error('Le prénom n\'est pas renseigné');
        }
        if (!validEmail.test(formData['email'])) {
            isErr = true;
            toast.error('L\'adresse email n\'est pas renseignée');
        }
        if (!validEmail.test(formData['usPrivateEmail'])) {
            isErr = true;
            toast.error('L\'adresse email personnelle n\'est pas renseignée');
        }
        if (!validPhone.test(formData['usPhone'])) {
            isErr = true;
            toast.error('Le numéro de téléphone n\'est pas renseigné');
        }
        if (!validPhone.test(formData['usPrivatePhone'])) {
            isErr = true;
            toast.error('Le numéro de téléphone personnel n\'est pas renseigné');
        }
        if (!validStr.test(formData['usAddress'])) {
            isErr = true;
            toast.error('L\'adresse postale n\'est pas renseignée');
        }
        if (!validZipcode.test(formData['usZipcode'])) {
            isErr = true;
            toast.error('Le code postal n\'est pas renseignée');
        }
        if (!validCity.test(formData['usCity'])) {
            isErr = true;
            toast.error('La ville n\'est pas renseignée');
        }
        if (!validDate.test(formData['usBirthdate'])) {
            isErr = true;
            toast.error('L\'adresse email n\'est pas renseignée');
        }
        if (!validDate.test(formData['usBirthplace'])) {
            isErr = true;
            toast.error('L\'adresse email n\'est pas renseignée');
        }
        // if (!validStr.test(formData['usGroup'])) {
        //     isErr = true;
        //     toast.error('Le groupe n\'est pas renseigné');
        // }
        
        console.log(isErr);
    
        return isErr;
    }

    function handleInputChange(name, value) {
        setFormData({ ...formData, [name]: value });
    }


    return (
        <>
            <div className="div-form">

                {formData &&
                    <form className="form-client" onSubmit={handleOnSubmitProfil}>

                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    className="form-input"
                                    name="usName"
                                    value={formData.usName}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Nom"
                                    onChange={(e) => { handleInputChange('usName', e); }} /></div>

                            <div className="col">
                                <InputLabel
                                    name="usFirstname"
                                    value={formData.usFirstname}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Prénom"
                                    onChange={(e) => { handleInputChange('usFirstname', e); }} />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="email"
                                    value={formData.email}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Email"
                                    onChange={(e) => { handleInputChange('email', e); }} />
                            </div>

                            <div className="col">
                                <InputLabel
                                    name="usPrivateEmail"
                                    value={formData.usPrivateEmail}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Email personnel"
                                    onChange={(e) => { handleInputChange('usPrivateEmail', e); }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 label">
                                <InputLabel
                                    name="usPhone"
                                    value={formData.usPhone}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Téléphone"
                                    onChange={(e) => { handleInputChange('usPhone', e); }} /></div>

                            <div className="col-6">
                                <InputLabel
                                    name="usPrivatePhone"
                                    value={formData.usPrivatePhone}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Téléphone personnel"
                                    onChange={(e) => { handleInputChange('usPrivatePhone', e); }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col ">
                                <InputLabel
                                    name="usAddress"
                                    value={formData.usAddress}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Adresse"
                                    onChange={(e) => { handleInputChange('usAddress', e); }} />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="usZipcode"
                                    value={formData.usZipcode}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Code Postal"
                                    onChange={(e) => { handleInputChange('usZipcode', e); }} />
                            </div>
                            <div className="col">
                                <InputLabel
                                    name="usCity"
                                    value={formData.usCity}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Ville"
                                    onChange={(e) => { handleInputChange('usCity', e); }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <InputLabel
                                    name="usBirthdate"
                                    value={formData.usBirthdate}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Date de naissance "
                                    onChange={(e) => { handleInputChange('usBirthdate', e); }} />
                            </div>
                            <div className="col">
                                <InputLabel
                                    name="usBirthplace"
                                    value={formData.usBirthplace}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Lieu de naissance"
                                    onChange={(e) => { handleInputChange('usBirthplace', e); }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <InputLabel
                                    name="usGroup"
                                    value={formData.usGroup}
                                    disabled={isShowProfil}
                                    defaultStyle='secondary'
                                    label="Groupe"
                                    onChange={(e) => { handleInputChange('usGroup', e); }} />
                            </div>
                        </div>

                        {!isShowProfil &&
                            <ButtonsForm route={`/`} isSaveButton={true} isBackButton={true} />
                        }
                    </form>
                }
            </div>
        </>
    )

};

export default ProfilForm;