import React from 'react';
import PropTypes from 'prop-types';

const History = ({ color, fill, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g>
            <path
                color={color}
                fill={fill}
                d="M21.292 8.497a8.957 8.957 0 0 0-1.928-2.862a9.004 9.004 0 0 0-4.55-2.452a9.09 9.09 0 0 0-3.626 0a8.965 8.965 0 0 0-4.552 2.453a9.048 9.048 0 0 0-1.928 2.86A8.963 8.963 0 0 0 4 12l.001.025H2L5 16l3-3.975H6.001L6 12a6.957 6.957 0 0 1 1.195-3.913a7.066 7.066 0 0 1 1.891-1.892a7.034 7.034 0 0 1 2.503-1.054a7.003 7.003 0 0 1 8.269 5.445a7.117 7.117 0 0 1 0 2.824a6.936 6.936 0 0 1-1.054 2.503c-.25.371-.537.72-.854 1.036a7.058 7.058 0 0 1-2.225 1.501a6.98 6.98 0 0 1-1.313.408a7.117 7.117 0 0 1-2.823 0a6.957 6.957 0 0 1-2.501-1.053a7.066 7.066 0 0 1-1.037-.855l-1.414 1.414A8.985 8.985 0 0 0 13 21a9.05 9.05 0 0 0 3.503-.707a9.009 9.009 0 0 0 3.959-3.26A8.968 8.968 0 0 0 22 12a8.928 8.928 0 0 0-.708-3.503z"
            />
        </g>
    </svg>
);

History.propTypes = {
    color: PropTypes.string,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

History.defaultProps = {
    color: '#FBFCFC',
    fill: '#FBFCFC',
    width: 18.605,
    height: 18.605,
};

export default History;
