import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import logo from 'assets/images/logo/logo2.png';
import ArrowIcon from 'components/icons/ArrowIcon';

import Tachometer from 'components/icons/Tachometer';
import Search from 'components/icons/Search';
import FileSignature from 'components/icons/FileSignature';
import Comment from 'components/icons/Comment';
import User from 'components/icons/User';

import "./sidebar.style.scss";
import History from 'components/icons/History';
import Cogs from 'components/icons/Cogs';

export default function Sidebar() {
  const [isCloseClass, setIsCloseClass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {

    // At The first time
    if (!isLoading) {

      if (window.outerWidth >= 992) {
        setIsCloseClass(false);
      }
      else {
        setIsCloseClass(true);
      }
      setIsLoading(true);

    }


    function handleResize() {

      let elt = document.querySelector(".side-bar");
      if (window.outerWidth >= 992) {
        if (isCloseClass) {
          elt.classList.remove("close");
          setIsCloseClass(false);
        }
      }
      else {
        if (!isCloseClass) {
          setIsCloseClass(true);
          elt.classList.add("close");
        }

      }
    }

    window.addEventListener('resize', handleResize)
  }, [isCloseClass, isLoading]);

  useEffect(() => {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
        arrowParent.classList.toggle("showMenu");
      });
    }
  }, []);
  return (
    <div className="side-bar">
      <div className="logo-details">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Solutions 909 logo"
        />
        <span className="logo_name">Admin Machine</span>
      </div>
      <ul className="nav-links">
        <li>
          <a href={`/`}>

            <i><Tachometer /></i>
            <span className="link_name">Dashboard</span>
          </a>
        </li>
        <li>
          <div className="iocn-link">
            <a href="/clients">
              <i><Search /></i>
              <span className="link_name">Clients</span>
            </a>
            <i className='arrow'><ArrowIcon width={15} height={15} /></i>
            {/* <i className='bx bxs-chevron-down arrow' ></i> */}
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="/clients">Clients</a></li>
            <li>
              <NavLink to="/client/add" className="link" activeClassName="active"> Nouveau</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <a href="/support">
            <i><Comment /></i>
            <span className="link_name">Support</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="/support">Support</a></li>
          </ul>
        </li>
        <li>
          <div className="iocn-link">
            <a href="/users">
              <i><User color='#FBFCFC' fill='#FBFCFC' /></i>
              <span className="link_name">Equipe</span>
            </a>
            <i className='arrow'><ArrowIcon width={15} height={15} /></i>
            {/* <i className='bx bxs-chevron-down arrow' ></i> */}
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="/users">Equipe</a></li>
            <li><a href="/registration">Nouveau</a></li>
          </ul>
        </li>

        <li>
          <div className="iocn-link">
            <a href="/devis">
              <i><FileSignature /></i>
              <span className="link_name">Devis</span>
            </a>
            <i className='arrow'><ArrowIcon width={15} height={15} /></i>
            {/* <i className='bx bxs-chevron-down arrow' ></i> */}
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="/devis">Devis</a></li>
            <li><a href="/devis/add">Nouveau</a></li>
          </ul>
        </li>
        <li>
          <a href="/log">
            <i> <History /></i>
            <span className="link_name">Log</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="/log">Log</a></li>
          </ul>
        </li>
        <li>
          <div className="iocn-link">
            <a href="/configuration">
              <i><Cogs color='#FBFCFC' fill='#FBFCFC' /> </i>
              <span className="link_name">Configuration</span>
            </a>
            <i className='arrow'><ArrowIcon width={15} height={15} /></i>
            {/* <i className='bx bxs-chevron-down arrow' ></i> */}
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="/configuration">Configuration</a></li>
            <li><a href="/configuration/generale">Générale</a></li>
            <li><a href="/configuration/system">Système</a></li>
          </ul>
        </li>
      </ul>
    </div>





  );
}
