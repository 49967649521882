import React from 'react';
import { v1 } from 'uuid';
import PropTypes from 'prop-types';

// import actionsProps from 'components/propTypes/actionsold';
// import userProps from 'components/propTypes/user';

import TableHead from './TableHead';

import './table.style.scss';


const Table = ({ header, data, RowElement, handleRemoveClient }) => (
  <table className='table table-hover' data-toggle="table" data-search="true" data-show-columns="true">
    <TableHead items={header} />
    <tbody>
      {data?.map(row => (
        // <RowElement key={v1()} data={row} actions={actions} />
        <RowElement key={v1()} data={row} handleRemoveClient={handleRemoveClient} />
      ))}
    </tbody>
  </table>
)

Table.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  // data: PropTypes.arrayOf(userProps),
  RowElement: PropTypes.func.isRequired,
  // actions: actionsProps.isRequired,
}

Table.defaultProps = {
  data: [],
}

export default Table;