import React from 'react';

import RegistrationForm from './form';

// import logo from 'assets/images/logo.png';
// import './Registration.style.scss';

// https://codesandbox.io/s/n7l025m5y4?file=/src/index.js

const Registration = () => {
    return (
      <div >
        <RegistrationForm />
      </div>
    );

};

export default Registration;
