import API from 'services/API';


//CLIENTS
export const getAllQuotes = () => API.get(`/devis`);

export const addQuote = (quote, config) => API.post(`/devis/new`, quote, config);
export const updateQuote = (quote) => API.patch(`/devis/${quote.id}/edit`, quote);

export const getAllProducts = () => API.get(`/products`);
export const addProducts = (product) => API.post(`/products/new`, product);
export const updateProducts = (product) => API.patch(`/products/${product.id}/edit`, product);

export const getAllQuoteStatus = () => API.get(`/quote/status`);
export const addQuoteStatus = (quoteStatus) => API.post(`/quote/status/new`, quoteStatus);
export const updateQuoteStatus = (quoteStatus) => API.patch(`/quote/status/${quoteStatus.id}/edit`, quoteStatus);