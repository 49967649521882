import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

import { toast } from 'react-toastify';
import { validName, validEmail, validDate } from 'utils';

import ButtonsForm from 'components/generics/ButtonsForm';
import Title from 'components/generics/Title';


export default function EditUser() {
    const location = useLocation();
    const [formData, setFormData] = useState(location.state.user);

    const handleInputChange = (event) => {
        const {
            name,
            value
        } = event.target;

        setFormData({ ...formData, [name]: value });

    };

    function validate(formData) {
        let isErr = false;
        // console.log(formData);

        if (!isErr && !validName.test(formData['login'])) {
            isErr = true;
            toast.error('Le login n\'est pas renseigné');
        }

        if (!isErr && !validName.test(formData['roles'])) {
            isErr = true;
            toast.error('Le roles n\'est pas renseigné');
        }
        if (!isErr && !validEmail.test(formData['email'])) {
            isErr = true;
            toast.error('Le email n\'est pas renseigné');
        }
        if (!isErr && !validName.test(formData['usGroup'])) {
            isErr = true;
            toast.error('Le groupe n\'est pas renseigné');
        }
        if (!isErr && !validDate.test(formData['usStartedAt'])) {
            isErr = true;
            toast.error('La date de début n\'est pas renseignée');
        }
        // if (!isErr && !validDate.test(formData['usEndedAt'])) {
        //     isErr = true;
        //     toast.error('La date de fin n\'est pas renseignée');
        // }
        // if (!isErr && !validDate.test(formData['usBlockedAt'])) {
        //     isErr = true;
        //     toast.error('La date de blocage n\'est pas renseignée');
        // }

        return isErr;

    }

    const handleOnSubmit = (event) => {
        if (event) event.preventDefault();
        // console.log("clientForm handleOnSubmit");

        // if (!validate(formData)) {
        //     props.handleOnSubmit(formData);
        // }
    };

    return (
        
        <div className="main-form div-edituser" >
            <Title
                className="div-header"
                text="Editer un membre" />
            <div className="div-form">
                <form onSubmit={handleOnSubmit}>

                    <label className="input-control"
                        type="text"
                        name="login"
                        value={formData.login}
                        placeholder="Saisissez le login"
                        onChange={handleInputChange}>Identifiant</label>

                    <label className="input-control"
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="Saisissez l'email"
                        onChange={handleInputChange}> Email </label>

                    <label className="input-control"
                        type="text"
                        name="usGroup"
                        value={formData.usGroup}
                        placeholder="Saisissez le groupe"
                        onChange={handleInputChange}> Groupe </label>

                    <label className="input-control"
                        type="datetime"
                        name="usStartedAt"
                        value={formData.usStartedAt}
                        placeholder="Date de début"
                        onChange={handleInputChange}> Date de début </label>

                    <label className="input-control"
                        type="datetime"
                        name="usEndedAt"
                        value={formData.usEndedAt}
                        placeholder="Date de fin"
                        onChange={handleInputChange}> Date de fin </label>


                    <ButtonsForm route={`/users`} isSaveButton={true}
                    isBackButton={true} />

                </form>
            </div>
        </div>
    )
}
