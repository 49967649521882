export const minimalRoleRequired = (roles, minimal) => {
  const roleRank = {
    SUPER_ADMIN: 4,
    ADMIN: 3,
    TECHNICIAN: 2,
    USER: 1,
  }

  if (!roles || !minimal) {
    return (false);
  }

  return ((roleRank[roles] >= minimal))
}

export const addZero = (num) => (num <= 9 ? `0${num}` : num)

export const formatDate = (dateRAW) => {
  const date = new Date(dateRAW);


  return (`${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}/${date.getFullYear()}`);
}

export const formatDateTime = (dateRAW) => {
  const date = new Date(dateRAW);

  // if one digit , force two digits with addZero function
  let dd = addZero(date.getDate());
  let MM = addZero(date.getMonth() + 1); //January is 0!
  let yyyy = date.getFullYear();
  let HH = addZero(date.getHours());
  let mm = addZero(date.getMinutes());
  let ss = addZero(date.getSeconds());

  // Y-m-d H:i:s
  let dformat = yyyy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm + ':' + ss
  return dformat;
}

export const formatNumberWithSpaces = (num) => {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
}


export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const validLogin = new RegExp('^(?=.*[a-z])(?=.*[A-Z]).{5,8}$');

export const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$');

export const validName = new RegExp("^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$");

export const validPhone = new RegExp("^0[0-9]{9}$");

export const validZipcode = new RegExp("[0-9]{5}");

export const validOrgCode = new RegExp("[A-Z0-9]{3}");

export const validCity = new RegExp("^([a-zA-Z0-9\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9\u0080-\u024F]*$");

export const validStr = new RegExp("^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-_]+$");

export const validNumeric = new RegExp("[0-9]");

export const validDate = new RegExp("/^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/")


export const convertIdOrganismType = (id, organismTypes) => {
  let typeOrg = null;
  typeOrg = organismTypes.find((typ) => {
    if (id === typ.id) {
      return typ;
    }
  });
  return typeOrg;
}


export const convertIdFunctionCategories= (id, functionCategories) => {
  let functionCat = null;
  functionCat = functionCategories.find((fct) => {
    if (id === fct.id) {
      return fct;
    }
  });
  return functionCat;
}

export const convertIdClients= (id, clients) => {
  let client = null;
  client = clients.find((clt) => {
    if (id === clt.id) {
      return clt;
    }
  });
  return client;
}


export const convertIdFilesCat= (id, filesCat) => {
  let fileCat = null;
  fileCat = filesCat.find((type) => {
    if (id === type.id) {
      return type;
    }
  });
  return fileCat;
}



export const calculateHtAmount = (q, p) => {
  return q * p;
}

export const calculateTTCAmount = (ht, tva) => {
  return (ht * (tva / 100)) + ht;
}


export const getDirDoc = (typeOfDocument) => {
  let dirDoc = "";
  switch (typeOfDocument) {
    case 'DEV':
        dirDoc = "devis";
        break;
    case "BCO":
        dirDoc = "bon_commandes";
        break;
    case "FAC":
        dirDoc = "factures";
        break;
    case "REL":
        dirDoc = "relances";
        break;
    case "CON":
        dirDoc = "contrats";
        break;
    case "DOC":
        dirDoc = "documents";
        break;
    default:
        dirDoc = "documents";
        break;
  }
  return dirDoc;
}