import React from 'react';
// import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import Table from 'components/generics/Table/Table';
import UserTableRow from './UserTableRow';

// import UserPlus from 'components/icons/UserPlus';
import Title from 'components/generics/Title';

const HEADER_TABLE = ['Nom', 'Prénom', 'Email', 'Téléphone', 'Actions'];

const UsersHome = ({ user }) => {
    const handleRemoveUser = (id) => {
        // setClients(clients.filter((client) => client.id !== id));

        // console.log("handleRemoveUser");
    };

    return (

        <>
          
                {/* CODE DE BASE <div className="table-header d-flex justify-space-between pb-3">
                    
                    <h1 className="card-title text-center flex-2">Annuaire S909</h1>
                    <NavLink to="/registration" className="px-3" activeClassName="active">
                        <UserPlus width="50" height="50" />
                    </NavLink>
                </div> */}

                <Title
                className=""
                text="Annuaire"
                isAddButton={true}
                routeAdd="/registration"
                />
           
                <div className="div-list">
                    {!_.isEmpty(user) ? (
                        <Table header={HEADER_TABLE}
                            data={user}
                            RowElement={UserTableRow}
                            handleRemoveUser={handleRemoveUser}
                        />

                    ) : (
                        <p className="message">L'annuaire est vide</p>
                    )}
                </div>
         


        </>
    );
}

export default UsersHome;
