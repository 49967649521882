import React from "react";
import useAuth from 'providers/Auth/useAuth';



import Bell from "components/icons/Bell";
import Bolt from "components/icons/Bolt";
import User from "components/icons/User";
import Chart from "components/icons/Chart";
import Envelope from "components/icons/Envelope";
import Logout from "components/icons/Logout";

import "./topbar.style.scss";

export default function Topbar() {
	const { user, logout } = useAuth();

	return (

		<nav className="navbar navbar-expand-sm navbar-light bg-light">
			<div className="container-fluid">
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto mb-2 mb-lg-0">
						<li className="nav-item active">
							<a href="/" className="nav-link active">
								<Envelope />
							</a>
						</li>

						<li className="nav-item active">
							<a href="/" className="nav-link active">
								<Bell />
							</a>
						</li>
						<li className="nav-item active">
							<a href="/" className="nav-link active">
								<Bolt />
							</a>
						</li>
						<li className="nav-item active dropdown">
							<a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								Mon espace
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<a href={`/user/${user.slug}/profil`} className="dropdown-item">
										<User /> Mon profil</a>
								</li>
								<li>
									<a href="/" className="dropdown-item">
										<Chart />Activité</a>
								</li>
								<li>	<a href="/" className="dropdown-item">
									<Envelope />Messages
								</a></li>
								<li><a href="/" className="dropdown-item">
									<Bell />Notifications
								</a></li>
								<li><hr className="dropdown-divider" /></li>
								<li className="nav-item">
									<button className="dropdown-item" onClick={() => logout()}><Logout width={25.0} height={25.0} />Déconnexion</button>
								</li>
							</ul>

						</li>
						<li className="nav-item active m-auto
						">
							{user.usName} {user.usFirstname}
						</li>
					</ul>

				</div>
			</div>
		</nav>
	);
}
