import React from 'react'
import { useLocation } from 'react-router-dom';
// import { Page, Document, Image, StyleSheet, PDFViewer } from '@react-pdf/renderer';

// import QuoteTitle from './QuoteTitle';
// import QuoteHeader from './QuoteHeader';
// import QuoteInfo from './QuoteInfo';
// import QuoteFooter from './QuoteFooter';
// import QuoteSummary from './QuoteSummary';

// import logo from 'assets/images/logo/logo_couleur.jpg';
import Download from 'components/icons/Download';
import Print from 'components/icons/Print';
import IconButton from 'components/generics/IconButton';

import "./showQuote.css";
import Send from 'components/icons/Send';
import QuoteDocument from './QuoteDocument';
import PdfDocument from 'components/generics/PdfDocument';


// const FROM = {
//     name: "Frédéric FABREGUE",
//     email: "ffabregue@solutions909.fr",
//     phone: "07.67.98.59.88",
//     address: "6 rue du Capricorne",
//     zipcode: "13320",
//     city: "BOUC BEL AIR",
//     website: "http://solutions909.fr"
// };

// const styles = StyleSheet.create({
//     page: {
//         fontFamily: 'Helvetica',
//         fontSize: 11,
//         paddingTop: 30,
//         paddingLeft:60,
//         paddingRight:60,
//         lineHeight: 1.5,
//         flexDirection: 'column',
//     }, 
//     logo: {
//         width: 74,
//         height: 66,
//         marginLeft: 'auto',
//         marginRight: 'auto'
//     }
//   });

export default function ShowQuote() {


    const location = useLocation();
    let quote = location.state.quote ? location.state.quote : null;

    console.log(quote);

    const handleDownload = () => {
        let htmlData =  document.getElementsByClassName("invoice-content"); 

        PdfDocument(htmlData, "devis" );
    };
    const handlePrint = (quote) => {

    };
    const handleSend = (quote) => {

    };

    return (

<>
        <header className="div-header d-flex justify-space-between pb-3">
            <ul className="d-flex list-unstyled" >
                <li>
                    <IconButton
                        onClick={() => handleDownload()}
                        icon={<Download width={35.0} height={35.0} />}
                    />
                </li>
                <li>
                    <IconButton
                        onClick={() => handlePrint(quote)}
                        icon={<Print width={35.0} height={35.0} />}
                    />
                </li>
                <li>
                    <IconButton
                        onClick={() => handleSend(quote)}
                        icon={<Send width={35.0} height={35.0} />}
                    />
                </li>
            </ul>

    </header>
    <QuoteDocument quote={quote} />
    
</>








    );
}
