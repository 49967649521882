import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';

import { getFiles, uploadFiles } from 'services/API/Clients';
import { convertIdFilesCat } from 'utils';
import Select from 'components/generics/Select';
import './uploadFiles.style.scss';

export default class UploadFiles extends Component {

  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clientId = props.clientId;
    this.filesCat = props.filesCategories;

    this.state = {
      selectedFiles: undefined,
      selectedFilesCat: undefined,
      progressInfos: [],
      message: [],
      fileInfos: [],

    };
  }

  componentDidMount() {
    getFiles(this.clientId).then((response) => {
      this.setState({
        fileInfos: response.data,
      });
    });
  }



  upload(idx, file, fileCat) {
    let _progressInfos = [...this.state.progressInfos];

    const formdata = new FormData();
    formdata.append('file',  file);
    formdata.append('fileCatId', fileCat.id);
    formdata.append('fileSize',  file.size);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    // console.log(formdata);
    uploadFiles(this.clientId, formdata, config, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Téléchargement du fichier avec succès: " + file.name,
          ];
          return {
            message: nextMessage,
          };
        });

        return getFiles(this.clientId);
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            "Impossible de télécharger le fichier : " + file.name,
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;
    const selectedFilesCat = this.state.selectedFilesCat;
    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i], selectedFilesCat[i]);
        }
      }
    );
  }

  handleOnSubmit= (event) => {
    if (event) event.preventDefault();
    this.uploadFiles();

  }
  onDrop(files) {
    if (files.length > 0) {
      const defaultFileCat = this.filesCat[1];
      const selectedFilesCat = files.map(key => defaultFileCat);
      this.setState({ 
        selectedFiles: files, 
        selectedFilesCat: selectedFilesCat});
    }

  }

  onDropRejected(fileRejections, event){
    if (event) {event.preventDefault();}
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          toast.error('Le document sélectionné dépasse 10Mo.');
        }

        if (err.code === "file-invalid-type") {
          toast.error('Le type de document est invalide, seul les PDF sont acceptés.');
        }
      });
    });
  }


  

  render() {
    const { selectedFiles, selectedFilesCat, progressInfos, message, fileInfos } = this.state;
    return (
      <div>
        {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="mb-2" key={index}>
              <span>{progressInfo.fileName}</span>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                </div>
              </div>
            </div>
          ))}

        <div className="my-3">
          <Dropzone maxSize={10000000} 
          accept={'application/pdf'} 
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 0
                        ? `${selectedFiles.length} documents à ajouter
                         `
                        : <> </>}
                    </div>
                  ) : (
                    `Faites glisser et déposez les fichiers ici, ou cliquez pour sélectionner les fichiers PDF`
                  )}
                </div>
                <aside className="selected-file-wrapper">

                {
                  this.state.selectedFiles &&
                  
                  <form className="form-client" onSubmit={this.handleOnSubmit}>
                    <p> veuillez sélectionner le type document associé.</p>
                   {this.state.selectedFiles &&
                    <table>
                      <thead>
                        <tr >
                          <td>Documents</td>
                          <td>Type</td>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.selectedFilesCat ? this.state.selectedFilesCat.map((filecat, i) => 
                        <tr key={i}>
                          <td>{this.state.selectedFiles[i].path ? `${this.state.selectedFiles[i].path}` : '-'}</td>
                        <td className="td-files-cat">
                            <Select className='form' name='filesCat'
                            onChange={(e) => { 
                              let FilesCat = this.state.selectedFilesCat;
                              FilesCat[i] = convertIdFilesCat(+e, this.filesCat);
                              this.setState({
                                ...this.state,
                                selectedFilesCat: FilesCat
                              });
                            }}
                            options={this.filesCat}
                            value={this.state.selectedFilesCat[i].id} 
                            itemLabel='type'
                            itemValue='id'
                            />
                        </td>
                        </tr>
                      ) : <> </>}  
                      </tbody>
                    </table>
                    }     
                    <div className="div-button">
                    <button variant="primary" type="submit" className="link btn-primary" disabled={!selectedFiles}><i className='bx bx-save'></i> Save</button>
                    </div>
                  </form>
                }
                </aside>
              </section>
            )}
          </Dropzone>
          
        </div>

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}

        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header">List of Files</div>
            <ul className="list-group list-group-flush">
              {fileInfos &&
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.url}>{file.name}</a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
